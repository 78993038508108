import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppoinmentCardTwo from './helperComponents/AppoinmentCard';
import { useGetUserBySubscription } from '../../queries/queries/useGetUserBySubscription';

import { useSelector } from 'react-redux';
import Loading from '../HelperComponents/Loading';
import { useGetNewStartOffer } from '../../queries/queries/useGetNewStartOffer';
import RateNegotiation from './helperComponents/RateNegotiation';
import CustomerDetails from './helperComponents/CustomerDetails';
import DigitalAccess from './helperComponents/DigitalAccess';
import ConversionOptions from './helperComponents/ConversionOptions';
import Enquiries from './helperComponents/Enquiries';
import AdminRecomendations from './helperComponents/AdminRecomendations';

const UserProfile = () => {
  const { id } = useParams();
  const [toggleConversion, setToggleConversion] = useState(false);
  const { singleData } = useSelector((store) => store.singleUser);

  const { getUserBySubscription, error, loading } = useGetUserBySubscription();
  const { getNewStartOffer } = useGetNewStartOffer();

  useEffect(() => {
    getNewStartOffer();
  }, [getNewStartOffer]);
  if (error) {
  }
  const userData = singleData;

  useEffect(() => {
    getUserBySubscription({
      variables: {
        subscriptionid: id,
      },
    });
  }, [getUserBySubscription, id]);

  let isAppointment = false;
  if (userData?.appointmentdata || userData?.override) {
    isAppointment = true;
  }
  let isNaviga = false;
  if (userData?.subscribersource || userData?.subscribersource) {
    isNaviga = true;
  }

  const data = singleData;

  if (loading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }
  // if (!singleData) {
  //   return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  // }
  const containerStyle = {
    height: toggleConversion ? '670px' : '55px',
    transition: 'height 0.3s ease-in-out',
  };
  if (!singleData) {
    return (
      <div className="w-full flex justify-center mt-10">
        <h1 className="text-xl text-center font-bold pb-10">User not found</h1>
      </div>
    );
  }
//<AdminRecomendations id={id} />
  return (
    <div>
      <div className="flex flex-col mb-5 ">
        <CustomerDetails data={data} />
        <DigitalAccess data={data} />
        {!isNaviga && <Enquiries data={data} />}
        <RateNegotiation id={data.subscriptionid} />
        <div
          className="shadow-lg py-4 px-7 rounded overflow-hidden"
          style={containerStyle}>
          <h1
            className="text-2xl font-bold mb-8 text-gray-500 hover:cursor-pointer"
            onClick={() => setToggleConversion(!toggleConversion)}>
            Conversion Options
          </h1>
          {
            <>
              <ConversionOptions data={data} />
              <AppoinmentCardTwo
                id={data.subscriptionid}
                isAppointment={isAppointment}
                appointmentdata={userData.appointmentdata}
                override={userData.override}
              />
            </>
          }
        </div>
      </div>

      <Link
        to="/"
        className="w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-center ml-auto mb-10">
        Back
      </Link>
    </div>
  );
};

export default UserProfile;
