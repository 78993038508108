import { useLazyQuery, gql } from '@apollo/client';
import { listAjcoperationsadmindeliveryschedulebyzipcodes } from '../../graphql/queries';
import { useDispatch } from 'react-redux';
import { getDeliveryZipData } from '../../redux/reducers/deliveryZipCode';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

export const GET_DELIVERY_ZIP_CODE = gql`
  ${listAjcoperationsadmindeliveryschedulebyzipcodes}
`;
export const useGetDeliveryZipCode = () => {
  const dispatch = useDispatch();
  const [
    getDeliveryZipCode,
    {
      loading: deliveryZipLoading,
      error: deliveryZipError,
      data: deliveryZipData,
      refetch,
    },
  ] = useLazyQuery(GET_DELIVERY_ZIP_CODE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
    variables: {
      limit: 1000,
    },
    onCompleted: (data) => {
      const results =
        data.listAjcoperationsadmindeliveryschedulebyzipcodes.items.map(
          (element) => {
            return { ...element, unique: uuidv4() };
          }
        );
      dispatch(
        getDeliveryZipData({
          zipData: results,

          total:
            data.listAjcoperationsadmindeliveryschedulebyzipcodes.items.length,
        })
      );
    },
    onError: (error) => {
      toast.error('Something went wrong ');
    },
  });
  return {
    getDeliveryZipCode,
    deliveryZipLoading,
    deliveryZipError,
    deliveryZipData,
    refetch,
  };
};
