import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  token: null,
  loading: true,
  displayUsers: false,
  currentPage: 0,
  isTriggered: false,
  total: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserList: (state, { payload }) => {
      state.data = [...state.data, ...payload.userData];
      state.token = payload.token;
      state.loading = false;
      state.displayUsers = true;
      state.total = payload.total;
    },
    nextPage: (state) => {
      state.currentPage += 1;
    },
    previousPage: (state) => {
      state.currentPage -= 1;
    },
    clearData: () => {
      return {
        data: [],
        token: null,
        loading: true,
        displayUsers: false,
        currentPage: 0,
        isTriggered: true,
      };
    },
    showHtml: () => {
      return {
        data: [],
        token: null,
        loading: true,
        displayUsers: false,
        currentPage: 0,
        isTriggered: false,
      };
    },
  },
});

export default userSlice.reducer;

export const { getUserList, nextPage, previousPage, clearData, showHtml } =
  userSlice.actions;
