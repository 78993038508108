import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAdmin: false,
  adminButton: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    getAdmin: (state, { payload }) => {
      state.isAdmin = payload;
    },
    toggleAdminButton: (state) => {
      const t = !state.adminButton;
      state.adminButton = t;
    },
  },
});

export default adminSlice.reducer;

export const { getAdmin, toggleAdminButton } = adminSlice.actions;
