import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerIssues: [],
};

const cutomerIssueSlice = createSlice({
  name: 'customerIssues',
  initialState,
  reducers: {
    getAllIssues: (state, { payload }) => {
      state.customerIssues = [...payload];
    },
  },
});

export default cutomerIssueSlice.reducer;

export const { getAllIssues } = cutomerIssueSlice.actions;
