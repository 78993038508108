import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import RichEditor from './RichEditor';
import CustomerIssues from './CustomerIssues';
import { useSelector } from 'react-redux';

import { useCreateDigitalIssues } from '../../../queries/mutation/digitalIssues/useCreateDigitalIssue';
import { useGetDigitalIssues } from '../../../queries/queries/useGetDigitalIssues';

const Enquiries = ({ data }) => {
  const [selectedIssue, setSelectedIssue] = useState('-');
  const [issueDescription, setIssueDescription] = useState('');
  const [toggle, setToggle] = useState(false);
  const { createDigitalIssues, createLoading } = useCreateDigitalIssues();
  const issueDescriptionHandler = (issue) => {
    setIssueDescription(issue);
  };
  const { getDigitalIssue } = useGetDigitalIssues();
  useEffect(() => {
    getDigitalIssue({
      variables: {
        subscriptionid: data.subscriptionid,
      },
    });
  }, [data.subscriptionid, getDigitalIssue]);
  const { customerIssues } = useSelector((store) => store.customerIssues);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (selectedIssue !== '-' && issueDescription.length > 0) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await createDigitalIssues({
          variables: {
            input: {
              id: crypto.randomUUID(),
              subscriptionid: data.subscriptionid,
              csremail: user.attributes.email,
              customeremail: data?.email,
              issue: selectedIssue,
              issuedescription: issueDescription,
            },
          },
        });
        setIssueDescription('');
        setSelectedIssue('-');
        getDigitalIssue({
          variables: {
            subscriptionid: data.subscriptionid,
          },
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      toast.warning('Please enter all the values');
    }
  };

  const digitalIssues = [
    'Article View Not Working',
    'Ads Blurred',
    'Download Issue',
    'Password Reset',
    'Newsletter Update',
    'Education',
    'Registration Issue',
    'Authentication/login',
    'Browser/Performance Issue',
  ];

  const containerStyle = {
    height: toggle ? '670px' : '55px',
    transition: 'height 0.3s ease-in-out',
    overflowY: toggle ? 'scroll' : '',
  };
  if (createLoading) {
    <ReactLoading type="bars" color="d31c1e" />;
  }
  return (
    <div
      className=" h-full shadow-lg py-4 px-7 mb-10  rounded  overflow-hidden"
      style={containerStyle}>
      <h1
        className="text-2xl font-bold mb-3  text-gray-500 hover:cursor-pointer"
        onClick={() => setToggle((prev) => !prev)}>
        Digital Complaints
      </h1>
      <div className="flex items-start justify-between mt-3">
        <form
          onSubmit={submitHandler}
          className="inline-flex flex-col gap-4 w-[700px]">
          <h4 className="inline mr-1  font-semibold "> Complaint Type : </h4>

          <select
            className="p-4 border"
            name="selectIssues"
            value={selectedIssue}
            onChange={(e) => setSelectedIssue(e.target.value)}>
            <option value="-">-</option>
            {digitalIssues.map((issues, i) => {
              return (
                <option key={i} value={issues}>
                  {issues}
                </option>
              );
            })}
          </select>
          <h4 className="inline mr-1  font-semibold ">Description : </h4>
          <RichEditor
            issueDescription={issueDescription}
            issueDescriptionHandler={issueDescriptionHandler}
          />
          <button
            className="w-[80px] h-[40px] bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
            type="submit">
            Submit
          </button>
        </form>
        <CustomerIssues data={customerIssues} />
      </div>
    </div>
  );
};

export default Enquiries;
