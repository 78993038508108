import { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditor from 'ckeditor5-custom-build/builds/ckeditor';

const RichEditor = ({ issueDescription, issueDescriptionHandler }) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={issueDescription}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor) => {
          const data = editor.getData();

          issueDescriptionHandler(data);
        }}
      />
    </>
  );
};

export default RichEditor;
