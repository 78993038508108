import { useLazyQuery, gql } from '@apollo/client';
import { listAjcoperationsadmineemailtemplates } from '../../graphql/queries';

export const GET_ADMIN_TEMPLATES = gql`
  ${listAjcoperationsadmineemailtemplates}
`;

export const useGetAdminTemplates = () => {
  const [
    getAdminTemplates,
    { error: templateError, loading: templateLoading, data: templateData },
  ] = useLazyQuery(GET_ADMIN_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  });
  return {
    getAdminTemplates,
    templateError,
    templateLoading,
    templateData,
  };
};
