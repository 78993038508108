import { useSelector, useDispatch } from 'react-redux';
import { useGetDeliveryZipCode } from '../../queries/queries/useGetDeliveryZipCode';

import React, { useEffect, useState } from 'react';
import {
  nextPage,
  previousPage,
  setCurrentPage,
} from '../../redux/reducers/deliveryZipCode';
import EditableRow from '../HelperComponents/EditableRow';
import ReadOnlyRow from '../HelperComponents/ReadOnlyRow';
import { useDeleteDeliveryZip } from '../../queries/mutation/deliveryZip/useDeleteDeliveryZip';
import { useCreateDeliveryZip } from '../../queries/mutation/deliveryZip/useCreateDeliveryZip';
import Loading from '../HelperComponents/Loading';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormRow from '../HelperComponents/FormRow';
import DeliverySearch from '../HelperComponents/DeliverySearch';
import Toggle from '../HelperComponents/Toggle';
import CreateTable from '../HelperComponents/CreateTable';

const DeliveryZip = () => {
  const dispatch = useDispatch();
  const { getDeliveryZipCode, deliveryZipLoading, refetch } =
    useGetDeliveryZipCode();
  const { deleteDeliverySchedule, deleteDeliveryData, deleteDeliveryLoading } =
    useDeleteDeliveryZip();

  const { createDeliveryZip, createdDdeliveryLoading, createdDeliveryData } =
    useCreateDeliveryZip();
  useEffect(() => {
    if (createdDeliveryData || deleteDeliveryData) {
      getDeliveryZipCode();
    }
  }, [getDeliveryZipCode, createdDeliveryData, deleteDeliveryData]);

  useEffect(() => {
    getDeliveryZipCode();
  }, []);
  const { data, total, currentPage } = useSelector(
    (store) => store.deliveryZip
  );
  const { isAdmin, adminButton } = useSelector((store) => store.admin);

  const [query, setQuery] = useState('');
  const newArray = data.filter((item) => {
    return Object.entries(query).every(([key, val]) => {
      if (key && val && item[key]) {
        return item[key]
          ?.toLowerCase()
          .replace(/\s/g, '')
          .includes(val?.toLowerCase().replace(/\s/g, ''));
      }
    });
  });

  const resultData = newArray.slice(currentPage * 10, currentPage * 10 + 10);
  const [disabledPrev, setdisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  const [editable, setEditable] = useState();
  const [formData, setFormData] = useState();
  const [addFormData, setAddFormData] = useState({
    area: '',
    deliverytype: '',
    newdeliveryschedule: '',
    zipcode: '',
    calendlylink: '',
  });

  const [currentRow, setCurrentRow] = useState('');
  const [openDelivery, setOpenDelivery] = useState(false);
  const handleLoadMore = () => {
    dispatch(nextPage());
  };
  const tableHeader = [
    'Area',
    'Deliverytype',
    'New Delivery Schedule',
    'Zipcode',
    'Calendly',
  ];
  const tableKeys = [
    'area',
    'deliverytype',
    'newdeliveryschedule',
    'zipcode',
    'calendlylink',
  ];
  const rowNames = [];
  const previousHandler = () => {
    dispatch(previousPage());
  };

  const searchHandler = (e) => {
    dispatch(setCurrentPage());
    const q = { ...query };
    if (e?.target?.value.replace(' ', '').length > 0) {
      q[e.target.name] = e.target.value;
    } else if (e?.target?.name in q) {
      delete q[e.target.name];
    }

    setQuery(q);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormData(newFormData);
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };
  const deleteHandler = (res) => {
    deleteDeliverySchedule({
      variables: {
        input: {
          zipcode: res.zipcode,
        },
      },
    });
  };
  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setEditable('');
    await deleteDeliverySchedule({
      variables: {
        input: {
          zipcode: currentRow.zipcode,
        },
      },
    });
    await createDeliveryZip({
      variables: {
        input: {
          zipcode: formData.zipcode,
          area: formData.area,
          deliverytype: formData.deliverytype,
          newdeliveryschedule: formData.newdeliveryschedule,
          calendlylink: formData.calendlylink,
        },
      },
    });
  };

  const handleCancelClick = () => {
    setEditable('');
  };
  const editHandler = (res) => {
    setEditable(res.unique);
    setCurrentRow(res);
    setFormData({
      area: res.area,
      deliverytype: res.deliverytype,
      newdeliveryschedule: res.newdeliveryschedule,
      zipcode: res.zipcode,
      unique: res.unique,
      calendlylink: res.calendlylink,
    });
  };
  const addFormatHandler = (event) => {
    event.preventDefault();
    if (
      addFormData.area &&
      addFormData.deliverytype &&
      addFormData.newdeliveryschedule &&
      addFormData.zipcode &&
      addFormData.calendlylink
    ) {
      createDeliveryZip({
        variables: {
          input: {
            zipcode: addFormData.zipcode,
            area: addFormData.area,
            deliverytype: addFormData.deliverytype,
            newdeliveryschedule: addFormData.newdeliveryschedule,
            calendlylink: addFormData.calendlylink,
          },
        },
      });
      setAddFormData({
        area: '',
        deliverytype: '',
        newdeliveryschedule: '',
        zipcode: '',
        calendlylink: '',
      });
    } else {
      toast.warning('Please enter all the values');
    }
  };
  useEffect(() => {
    const isDisabled = () => {
      if (currentPage + 1 >= Math.ceil(newArray.length / 10)) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      if (currentPage - 1 < 0) {
        setdisabledPrev(true);
      } else {
        setdisabledPrev(false);
      }
    };
    isDisabled();
  }, [currentPage, data, newArray]);

  if (deliveryZipLoading || deleteDeliveryLoading || createdDdeliveryLoading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }
  return (
    <div>
      {adminButton && (
        <Toggle>
          <CreateTable
            tableKeys={tableKeys}
            tableValues={tableKeys}
            createFormData={addFormData}
            changeHandler={handleAddFormChange}
            submitHandler={addFormatHandler}
          />
        </Toggle>
      )}
      <table className="flex-grow w-full mb-10 table-fixed" role="table">
        <thead className="h-12 bg-gray-800">
          <tr>
            {tableHeader.map((header, index) => (
              <th
                key={header}
                className="border border-black pt-2 font-prompt-semiBold text-white ">
                <div className="flex flex-col items-center gap-2 p-2">
                  {header}
                  <DeliverySearch
                    value={query[tableKeys[index]] || ''}
                    name={tableKeys[index]}
                    changeHandler={searchHandler}
                  />
                </div>
              </th>
            ))}
            {adminButton && (
              <th className="border border-black pt-2 font-prompt-semiBold text-white">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {resultData.map((res, id) =>
            editable === res.unique && adminButton && isAdmin ? (
              <EditableRow
                key={res.unique}
                formData={formData}
                rowNames={tableKeys}
                handleEditFormChange={handleEditFormChange}
                handleCancelClick={handleCancelClick}
                handleEditFormSubmit={handleEditFormSubmit}
              />
            ) : (
              <ReadOnlyRow
                key={res.unique}
                tableKeys={tableKeys}
                res={res}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
              />
            )
          )}
        </tbody>
      </table>
      {newArray.length ? (
        <div className="flex justify-between mb-10">
          <button
            className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
            style={disabledPrev ? { visibility: 'hidden' } : {}}
            onClick={previousHandler}>
            Previous
          </button>
          <h2 className="font-prompt-semiBold text-xl ">
            Page {currentPage + 1} of {Math.ceil(newArray.length / 10)}
          </h2>
          <button
            className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
            style={disabledNext ? { visibility: 'hidden' } : {}}
            onClick={handleLoadMore}>
            Next
          </button>
        </div>
      ) : (
        ''
      )}
      <Link
        to="/"
        className="w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-center ml-auto mb-10">
        Back
      </Link>
    </div>
  );
};

export default DeliveryZip;
