import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchTerm: '',
  filterOption: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchTerm: (state, { payload }) => {
      state.searchTerm = payload.searchTerm;
      state.filterOption = payload.searchOption;
    },
  },
});

export default searchSlice.reducer;

export const { getSearchTerm } = searchSlice.actions;
