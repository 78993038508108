import { toast } from 'react-toastify';
import { createAjcoperationsadmindeliveryschedulebyzipcode } from '../../../graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import { GET_DELIVERY_ZIP_CODE } from '../../queries/useGetDeliveryZipCode';
export const useCreateDeliveryZip = (variables) => {
  const CREATE_DELIVERY_ZIP = gql`
    ${createAjcoperationsadmindeliveryschedulebyzipcode}
  `;

  const [
    createDeliveryZip,
    {
      error: deliveryError,
      data: createdDeliveryData,
      loading: createdDdeliveryLoading,
    },
  ] = useMutation(CREATE_DELIVERY_ZIP, {
    variables: variables,

    refetchQueries: [
      {
        query: GET_DELIVERY_ZIP_CODE,
        variables: { limit: 10000 },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      toast.success('Created Successfully');
    },
    onError: (error) => {
      toast.error('Something went wrong ');
    },
  });
  return {
    createDeliveryZip,
    createdDdeliveryLoading,
    deliveryError,
    createdDeliveryData,
  };
};
