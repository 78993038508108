import { useState } from 'react';
import './style.css';
const FormRow = ({ type, name, value, handleChange, labelText }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="input-group w-1/4">
      <label
        htmlFor={name}
        className={`${value || isFocused ? 'input_focus' : 'input_out_focus'}`}
      >
        {labelText}
      </label>
      <input
        className={`${value || isFocused ? 'dark_border' : ''}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
        id={name}
        value={value}
        name={name}
        onChange={handleChange}
      />
    </div>
  );
};

export default FormRow;
