import { getAjcoperationsadminsubscriptions } from '../../graphql/queries';
import { useLazyQuery, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getUser } from '../../redux/reducers/singleUser';
import { toast } from 'react-toastify';

export const useGetUserBySubscription = (variables) => {
  const dispatch = useDispatch();
  const GET_SINGLE_USER = gql`
    ${getAjcoperationsadminsubscriptions}
  `;

  const [getUserBySubscription, { error, data, loading }] = useLazyQuery(
    GET_SINGLE_USER,
    {
      variables: variables,
      // fetchPolicy: 'network-only',
      onError: (error) => {
        console.log(error);
        toast.error('Something went wrong Please try again');
      },
      onCompleted: (data) => {
        dispatch(getUser(data.getAjcoperationsadminsubscriptions));
      },
    }
  );
  return { getUserBySubscription, error, data, loading };
};
