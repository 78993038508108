import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { previousPage, nextPage } from '../../redux/reducers/newOffer';

import EditableRow from './EditableRow';
import ReadOnlyRow from './ReadOnlyRow';
import DeliverySearch from './DeliverySearch';
import TableFooter from './TableFooter';
import BackButton from './BackButton';
import Modal from './Modal';
import EditableModal from './EditableModal';

const TableBody = ({
  tableKeys,
  tableHeader,
  rowPerPage,
  data,
  currentPage,
  rowNames,
  editable,
  formData,
  query,
  searchHandler,
  editHandler,
  deleteHandler,
  handleEditFormChange,
  handleCancelClick,
  handleEditFormSubmit,
  isNewOffer,
  showModal,
  setShowModal,
}) => {
  const [disabledPrev, setdisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  const [readShowModal, setReadShowModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const dispatch = useDispatch();
  const { adminButton } = useSelector((store) => store.admin);
  const newArray = data.filter((item) => {
    return Object.entries(query).every(([key, val]) => {
      return item[key]
        ?.toLowerCase()
        .replace(/\s/g, '')
        .includes(val.toLowerCase()?.replace(/\s/g, ''));
    });
  });
  const results = newArray.slice(
    currentPage * rowPerPage,
    currentPage * rowPerPage + rowPerPage
  );

  const nextHandler = () => {
    dispatch(nextPage());
  };

  const previousHandler = () => {
    dispatch(previousPage());
  };
  useEffect(() => {
    const isDisabled = () => {
      if (currentPage + 1 >= Math.ceil(newArray.length / rowPerPage)) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      if (currentPage - 1 < 0) {
        setdisabledPrev(true);
      } else {
        setdisabledPrev(false);
      }
    };
    isDisabled();
  }, [currentPage, data, newArray, rowPerPage]);
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="flex-grow w-full mb-10  " role="table">
          <thead className="h-12 bg-gray-800">
            <tr>
              {tableHeader.map((header, index) => (
                <th
                  key={header}
                  className="border border-black pt-2  font-prompt-semiBold text-white">
                  <div className="flex flex-col items-center gap-2 p-2 min-w-[100px]">
                    <div className="h-12">{header}</div>
                    <DeliverySearch
                      value={query[tableKeys[index]] || ''}
                      name={tableKeys[index]}
                      changeHandler={searchHandler}
                    />
                  </div>
                </th>
              ))}
              {adminButton && (
                <th className="border border-black pt-2 font-prompt-semiBold text-white">
                  Actions
                </th>
              )}
            </tr>
          </thead>

          {results.map((res, id) =>
            editable === res.unique && !isNewOffer ? (
              <tbody key={id}>
                <EditableRow
                  formData={formData}
                  rowNames={rowNames}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                  handleEditFormSubmit={handleEditFormSubmit}
                />
              </tbody>
            ) : (
              <tbody key={id}>
                <ReadOnlyRow
                  isNewOffer={isNewOffer}
                  setModalData={setModalData}
                  setReadShowModal={setReadShowModal}
                  tableKeys={tableKeys}
                  res={res}
                  editHandler={editHandler}
                  deleteHandler={deleteHandler}
                />
              </tbody>
            )
          )}
        </table>
      </div>
      {newArray.length ? (
        <TableFooter
          disabledPrev={disabledPrev}
          rowPerPage={rowPerPage}
          previousHandler={previousHandler}
          currentPage={currentPage}
          newArray={newArray}
          disabledNext={disabledNext}
          handleLoadMore={nextHandler}
        />
      ) : (
        ''
      )}
      <BackButton />

      <Modal
        readShowModal={readShowModal}
        setReadShowModal={setReadShowModal}
        data={modalData}
      />
      <EditableModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={modalData}
        formData={formData}
        rowNames={rowNames}
        handleEditFormChange={handleEditFormChange}
        handleCancelClick={handleCancelClick}
        handleEditFormSubmit={handleEditFormSubmit}
      />
    </div>
  );
};

export default TableBody;
