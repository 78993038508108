import React from 'react';
import ReactLoading from 'react-loading';

export const Loading = ({ type, color, text }) => (
  <div className="w-full flex justify-center" style={{ marginTop: '28%' }}>
    <div className="flex">
      <div className="mr-8 flex items-center text-xl">{text}</div>
      <ReactLoading type={type} color={color} />
    </div>
  </div>
);

export default Loading;
