import CustomerCol from './CustomerCol';

const CustomerDetails = ({ data, name }) => {
  const col1 = [
    { customername: 'Customer Name' },
    { subscriptionid: 'Subscription Id' },
    { phone: 'Phone' },
    { email: 'Email' },
  ];
  const col3 = [
    { autopay: 'Autopay' },
    { laststartdate: 'Last Start Date' },
    { subscriptiontype: 'Subscription Type' },
    { monthlyrate: 'Monthly Rate' },
  ];
  const col4 = [
    { originalstartdate: 'Original Start Date' },
    { billingmethod: 'Billing Method' },
    { ipadserialnumber: 'IPad Serial Number' },
    { subscribersource: 'Subscription Source' },
  ];
  return (
    <div className=" h-full shadow-lg py-4 px-7 mb-10  rounded">
      <h1 className="text-2xl font-bold mb-8 text-gray-500">
        Customer Details
      </h1>
      <div className="flex  gap-2 justify-between">
        <CustomerCol data={data} col={col1} />
        <div className="flex flex-col gap-4 flex-wrap">
          <div>
            <h4 className="inline mr-1  font-semibold ">Address :</h4>
            <h4 className="inline">
              {`${data?.address}, ${data.city}, ${data.state}, ${data.zipcode}`}
            </h4>
          </div>
          <div>
            <h4 className="inline mr-1  font-semibold ">Area :</h4>
            <h4 className="inline">{data.area}</h4>
          </div>
          <div>
            <h4 className="inline mr-1  font-semibold ">Expiration Date :</h4>
            <h4 className="inline">{data.expiredate}</h4>
          </div>{' '}
          <div>
            <h4 className="inline mr-1  font-semibold ">
              Delivery Schedule ID :
            </h4>
            <h4 className="inline">{data.deliveryscheduleid}</h4>
          </div>
        </div>
        <CustomerCol data={data} col={col3} />
        <CustomerCol data={data} col={col4} />
      </div>
    </div>
  );
};

export default CustomerDetails;
