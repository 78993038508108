import { toast } from 'react-toastify';

import { useMutation, gql } from '@apollo/client';
import { createAjcoperationsadminoffers } from '../../../graphql/mutations';

export const CREATE_START_OFFER = gql`
  ${createAjcoperationsadminoffers}
`;

export const useCreateStartOffer = (variables) => {
  const [
    createStartOffer,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_START_OFFER, {
    variables,
    onCompleted: () => {
      toast.success('Created Successfully');
    },
    onError: (error) => {
      console.log(error.message);
      toast.error('Something went wrong ');
    },
  });

  return {
    createStartOffer,
    createData,
    createLoading,
    createError,
  };
};
