import React from 'react';
const index = () => {
  return (
    <div className="pt-64 max-w-[1600px] mx-auto ">
      <h1 className="text-3xl text-center font-extrabold pb-10">
        Unauthorized User
      </h1>
    </div>
  );
};

export default index;
