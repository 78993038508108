import React from 'react';
import Resources from '../../components/Resources/default';

const index = () => {
  return (
    <div className="pt-10 max-w-[1600px] mx-auto ">
      <h1 className="text-2xl text-center font-extrabold pb-10">Resources</h1>
      <Resources />
    </div>
  );
};

export default index;
