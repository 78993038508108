import { useLazyQuery, gql } from '@apollo/client';
import { listAjcoperationsadminhtmlpages } from '../../graphql/queries';
import { getAdminContent } from '../../redux/reducers/adminHtml';
import { useDispatch } from 'react-redux';

export const GET_ADMIN_HTML_PAGE = gql`
  ${listAjcoperationsadminhtmlpages}
`;

export const useGetAdminHtmlPage = () => {
  const dispatch = useDispatch();
  const [getHtmlPage, { error: getError, data: getData, loading: getLoading }] =
    useLazyQuery(GET_ADMIN_HTML_PAGE, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'ignore',
      variables: {
        limit: 1000,
      },
      onCompleted: (data) => {
        dispatch(
          getAdminContent({
            home: data.listAjcoperationsadminhtmlpages?.items[0]?.pagecontent,
            resource:
              data.listAjcoperationsadminhtmlpages?.items[1]?.pagecontent,
          })
        );
      },
    });
  return {
    getHtmlPage,
    getError,
    getData,
    getLoading,
  };
};
