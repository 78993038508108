import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  homeContent: '',
  resourceContent: '',
  loading: true,
};

const adminHtmlSlice = createSlice({
  name: 'adminHtml',
  initialState,
  reducers: {
    getAdminContent: (state, { payload }) => {
      state.homeContent = payload.home;
      state.resourceContent = payload.resource;
      state.loading = false;
    },
  },
});

export default adminHtmlSlice.reducer;
export const { getAdminContent } = adminHtmlSlice.actions;
