import { useQuery, gql } from '@apollo/client';
import { searchByRecommendationsSubId } from '../../graphql/queries';

export const GET_ADMIN_RECOMENDATIONS = gql`
  ${searchByRecommendationsSubId}
`;

export const useAdminRecomendations = (variables) => {
  const {
    data: adminRecomendationData,
    error: adminRecomendationError,
    loading: adminRecomendationLoading,
  } = useQuery(GET_ADMIN_RECOMENDATIONS, {
    variables,
  });
  return {
    adminRecomendationData,
    adminRecomendationError,
    adminRecomendationLoading,
  };
};
