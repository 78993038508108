import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setCurrentPage } from '../../redux/reducers/newOffer';
import { useCreateStartOffer } from '../../queries/mutation/startOffer/useCreateStartOffer';
import { useGetNewStartOffer } from '../../queries/queries/useGetNewStartOffer';
import { useDeleteStartOffer } from '../../queries/mutation/startOffer/useDeleteStartOffer';

import CreateTable from '../HelperComponents/CreateTable';
import TableBody from '../HelperComponents/TableBody';
import Toggle from '../HelperComponents/Toggle';
import Loading from '../HelperComponents/Loading';
import { toast } from 'react-toastify';
import NoData from '../Search/helperComponents/NoData';

const NewOffers = () => {
  const { createStartOffer, createData, createLoading } = useCreateStartOffer();
  const { getNewStartOffer, getLoading } = useGetNewStartOffer();
  const { deleteStartOffer, deleteLoading, deleteData } = useDeleteStartOffer();
  const { data, currentPage, loading } = useSelector((store) => store.newOffer);
  const { adminButton } = useSelector((store) => store.admin);

  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const [editable, setEditable] = useState('');
  const [currentRow, setCurrentRow] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [editFormData, setEditFormData] = useState({
    id: '',
    active: '',
    site: '',
    offercreatedate: '',
    offerstartdate: '',
    offerexpiredate: '',
    publication: '',
    ratecode: '',
    offercode: '',
    weeklyprice: '',
    totalprice: '',
    activationfee: '',
    offername: '',
    offerdescriptiion: '',
    campaigncode: '',
    source: '',
    deliveryschedule: '',
    merchandisecode: '',
    defaultoffer: '',
    ratetype: '',
    subsource: '',
  });

  const [createFormData, setCreateFormData] = useState({
    id: '',
    active: '',
    site: '',
    offercreatedate: '',
    offerstartdate: '',
    offerexpiredate: '',
    publication: '',
    ratecode: '',
    offercode: '',
    weeklyprice: '',
    totalprice: '',
    activationfee: '',
    offername: '',
    offerdescriptiion: '',
    campaigncode: '',
    source: '',
    deliveryschedule: '',
    merchandisecode: '',
    defaultoffer: '',
    ratetype: '',
    subsource: '',    
  });
  const tableKeys = [
    //'id',
    'offercode',
    'ratecode',
    'deliveryschedule',
    'source',
    'active',
    'weeklyprice',
    'totalprice',
    'offerexpiredate',
    'offername',
    'defaultoffer',
    //'site',
    // 'offercreatedate',
    // 'offerstartdate',
    // 'publication',
    // 'offerdescriptiion',
    // 'campaigncode',
    // 'source',
  ];

  const tableValues = [
    'Offer Code',
    'Rate Code',
    'Delivery Schedule',
    'Source',
    'Active',
    'Weekly Price',
    'Total Price',
    'Offer Expire Date',
    'Offer Name',
    'Standard Offer',
    //  'Id',
    //  'Active',
    //  'Site',
    //  'Offer Create Date',
    //  'offer start date',
    //  'offer expire date',
    //  'publication',
    // 'offer code',
    // 'weekly price',
    // 'total price',
    // 'activation fee',
    // 'offer name',
    // 'offer descriptiion',
    // 'campaigncode',
  ];
  const createTableKeys = [
    'id',
    'active',
    'site',
    'offercreatedate',
    'offerstartdate',
    'offerexpiredate',
    'publication',
    'ratecode',
    'offercode',
    'weeklyprice',
    'totalprice',
    'activationfee',
    'offername',
    'offerdescriptiion',
    'campaigncode',
    'source',
    'deliveryschedule',
    'merchandisecode',
    'defaultoffer',
    'ratetype',
    'subsource', 
  ];
  const createTableValues = [
    'Id',
    'Active',
    'Site',
    'Offer Create Date',
    'Offer Start Date',
    'Offer Expire Date',
    'Publication',
    'Ratecode',
    'Offer Code',
    'Weekly Price',
    'Total Price',
    'Activation Fee',
    'Offer Name',
    'Offer Description',
    'Campaign Code',
    'Source',
    'Delivery Schedule',
    'Merchandise Code',
    'Standard Offer',
    'Rate Type',
    'Subsource', 
  ];
  // Create Data Handler
  const createFormChangeHandler = (e) => {
    e.preventDefault();
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const newData = { ...createFormData };
    newData[fieldName] = fieldValue;
    setCreateFormData(newData);
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    for (const k in createFormData) {
      if (!createFormData[k]) {
        toast.warning('Fill all the values');
        return;
      }
    }
    await createStartOffer({
      variables: {
        input: createFormData,
      },
    });
  };

  //Edit Data Handler
  const handleEditFormChange = (e) => {
    e.preventDefault();

    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    const newData = { ...editFormData };
    newData[fieldName] = fieldValue;

    setEditFormData(newData);
  };
  const handleEditFormSubmit = async () => {
    try {
      await deleteHandler(currentRow);
      await createStartOffer({
        variables: {
          input: editFormData,
        },
      }); //editFormData
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  //Delete Handlers
  const deleteHandler = async (res) => {
    await deleteStartOffer({
      variables: {
        input: {
          id: res.id,
        },
      },
    });
  };

  //Search Handler

  const searchHandler = (e) => {
    dispatch(setCurrentPage());
    const q = { ...query };
    if (e?.target?.value.replace(' ', '').length > 0) {
      q[e.target.name] = e.target.value;
    } else if (e?.target?.name in q) {
      delete q[e.target.name];
    }

    setQuery(q);
  };

  //other handlers

  const editHandler = (res) => {
    setShowModal(true);
    setEditable(res.unique);
    setCurrentRow(res);
    const obj = {};
    createTableKeys.map((tk) => (obj[tk] = res[tk]));
    setEditFormData(obj);
  };

  const handleCancelClick = () => {
    setEditable('');
    setShowModal(false);
  };

  //Refetch

  useEffect(() => {
    if (createData || deleteData) {
      getNewStartOffer();
    }
  }, [createData, deleteData, getNewStartOffer]);

  useEffect(() => {
    getNewStartOffer();
  }, [getNewStartOffer]);

  if (getLoading || createLoading || deleteLoading || loading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }

  return (
    <div>
      {adminButton && (
        <Toggle>
          <CreateTable
            createData={createStartOffer}
            tableKeys={createTableKeys}
            tableValues={createTableValues}
            createFormData={createFormData}
            changeHandler={createFormChangeHandler}
            submitHandler={createSubmitHandler}
          />
        </Toggle>
      )}

      {data.length === 0 ? (
        <NoData message="No Data" />
      ) : (
        <TableBody
          tableKeys={tableKeys}
          tableHeader={tableValues}
          rowPerPage={10}
          data={data}
          currentPage={currentPage}
          rowNames={tableKeys}
          editable={editable}
          formData={editFormData}
          query={query}
          searchHandler={searchHandler}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          handleEditFormChange={handleEditFormChange}
          handleCancelClick={handleCancelClick}
          handleEditFormSubmit={handleEditFormSubmit}
          isNewOffer={true}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default NewOffers;
