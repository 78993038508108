import FormRow from './FormRow';
const CreateTable = ({
  tableKeys,
  tableValues,
  createFormData,
  changeHandler,
  submitHandler,
}) => {
  return (
    <form onSubmit={submitHandler}>
      <div className="flex h-[300px] flex-col items-start flex-wrap gap-5 ">
        {tableKeys.map((k, i) => (
          <FormRow
            key={k}
            type="text"
            name={k}
            value={createFormData[k]}
            handleChange={changeHandler}
            labelText={tableValues[i]}
          />
        ))}
      </div>
      <button className="border p-2 w-40 h-12 rounded font-prompt-semiBold text-l disabled:bg-dn-nav-color text-white bg-gray-800">
        Add Offer
      </button>
    </form>
  );
};

export default CreateTable;
