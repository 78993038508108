import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getCurrentAuth: (state, { payload }) => {
      if (typeof payload == 'string') {
        state.currentUser = payload;
      } else {
        state.currentUser = Object.keys(payload)[0];
      }
    },
  },
});

export default authSlice.reducer;

export const { getCurrentAuth } = authSlice.actions;
