import { useRef } from 'react';
import FormRow from './FormRow';

const EditableModal = ({
  showModal,
  setShowModal,
  data,
  formData,
  rowNames,
  handleEditFormChange,
  handleEditFormSubmit,
  handleCancelClick,
}) => {
  const createTableKeys = [
    'id',
    'active',
    'site',
    'offercreatedate',
    'offerstartdate',
    'offerexpiredate',
    'publication',
    'ratecode',
    'offercode',
    'weeklyprice',
    'totalprice',
    'activationfee',
    'offername',
    'offerdescriptiion',
    'campaigncode',
    'source',
    'deliveryschedule',
    'merchandisecode',
    'defaultoffer',
    'ratetype',
    'subsource', 
  ];
  const createTableValues = [
    'Id',
    'Active',
    'Site',
    'Offer Create Date',
    'Offer Start Date',
    'Offer Expire Date',
    'Publication',
    'Ratecode',
    'Offer Code',
    'Weekly Price',
    'Total Price',
    'Activation Fee',
    'Offer Name',
    'Offer Description',
    'Campaign Code',
    'Source',
    'Delivery Schedule',
    'Merchandise Code',
    'Standard Offer',
    'Rate Type',
    'Subsource',
  ];
  const modalRef = useRef('');
  const modalHandler = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };
  let results = Object.entries(data);
  const l = results.length;
  results = results.slice(1, l - 3);

  return (
    <>
      {showModal && (
        <div
          ref={modalRef}
          onClick={modalHandler}
          className="fixed w-full h-full flex items-center justify-center bg-black bg-opacity-40 left-0 top-0">
          <div className="flex h-[500px] w-full max-w-[1600px] flex-col items-center justify-center bg-white flex-wrap gap-5 ">
            {createTableKeys.map((k, i) => (
              <FormRow
                key={k}
                type="text"
                name={k}
                value={formData[k]}
                handleChange={handleEditFormChange}
                labelText={createTableValues[i]}
              />
            ))}
            <div className="flex gap-2 p-2 items-center justify-center">
              <button
                type="submit"
                className="bg-gray-800  text-white px-2"
                onClick={handleEditFormSubmit}>
                Save
              </button>
              <button
                type="button"
                className="bg-gray-800  text-white px-2"
                onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
          {/* <div className="border border-black p-10 bg-white">
            {rowNames.map((row) => (
              <td
                className="border border-gray-300 font-prompt-regular h-10 "
                key={row}
              >
                <input
                  className="h-full w-full"
                  type="text"
                  name={row}
                  value={formData[row]}
                  key={row}
                  onChange={handleEditFormChange}
                />
              </td>
            ))}

            <div className="flex gap-2 p-2 items-center justify-center">
              <button
                type="submit"
                className="bg-gray-800  text-white px-2"
                onClick={handleEditFormSubmit}
              >
                Save
              </button>
              <button
                type="button"
                className="bg-gray-800  text-white px-2"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default EditableModal;
