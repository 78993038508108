import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { deleteAjcoperationsadmineemailtemplates } from '../../../graphql/mutations';

export const DELETE_ADMIN_TEMPLATE = gql`
  ${deleteAjcoperationsadmineemailtemplates}
`;

export const useDeleteAdminTemplate = (variables) => {
  const [
    deleteAdminTemplate,
    {
      data: deleteAdminTemplateData,
      loading: deleteAdminTemplateLoading,
      error: deleteAdminTemplateError,
    },
  ] = useMutation(DELETE_ADMIN_TEMPLATE, {
    variables: variables,
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Template deleted');
    },
    onError: (error) => {
      toast.error('Something went wrong');
      console.log(error);
    },
  });
  return {
    deleteAdminTemplate,
    deleteAdminTemplateData,
    deleteAdminTemplateLoading,
    deleteAdminTemplateError,
  };
};
