import './App.css';

import { ToastContainer } from 'react-toastify';

import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

import { ApolloProvider } from '@apollo/client';
import { client } from './queries/graphql';

import { store } from './redux/store';
import { Provider } from 'react-redux';
import './App.css';
import Index from './pages/Router';

Amplify.configure(awsconfig);

const theme = {
  name: 'Auth Example Theme',
  tokens: {
    colors: {
      background: {
        primary: {
          value: 'white',
        },
        secondary: {
          value: 'white',
        },
      },
      font: {
        interactive: {
          value: '#d31c1e',
        },
      },
      brand: {
        primary: {
          10: 'white',
          80: '#d31c1e',
          90: '#ff7359',
          100: '#d31c1e',
        },
      },
    },
    components: {
      tabs: {
        item: {
          _focus: {
            color: {
              value: 'black',
            },
          },
          _hover: {
            color: {
              value: 'black',
            },
          },
          _active: {
            color: {
              value: 'black',
            },
          },
        },
      },
    },
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator hideSignUp={true}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <BrowserRouter>
              <Index />
            </BrowserRouter>
          </Provider>
        </ApolloProvider>
      </Authenticator>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
