import React from 'react';
import DeliveryZip from '../../components/DeliveryZip/default';
const index = () => {
  return (
    <div className="pt-10 max-w-[1600px] mx-auto ">
      <h1 className="text-2xl text-center font-extrabold pb-10">
        Delivery Zip
      </h1>
      <DeliveryZip />
    </div>
  );
};

export default index;
