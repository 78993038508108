import { useState, useEffect } from 'react';
import { getSearchTerm } from '../../redux/reducers/search';
import { useDispatch, useSelector } from 'react-redux';
import { clearData } from '../../redux/reducers/user';
import { useGetUserDetails } from '../../queries/queries/useGetUserDetails';
import Loading from '../HelperComponents/Loading';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import close from '../../resources/close.svg';
const Search = () => {
  const [term, setTerm] = useState('');
  const [option, setOption] = useState('subscriptionid');
  const [isClose, setIsClose] = useState(false);

  const dispatch = useDispatch();

  const { getUserDetails, loading } = useGetUserDetails();
  useEffect(() => {
    if (term) {
      setIsClose(true);
    } else {
      setIsClose(false);
    }
  }, [term]);
  const { isAdmin } = useSelector((store) => store.admin);

  const options = [
    {
      label: 'Subscription Id',
      value: 'subscriptionid',
    },    
    {
      label: 'Customer Name',
      value: 'customername',
    },
    {
      label: 'Phone Number',
      value: 'phone',
    },

    {
      label: 'Email Address',
      value: 'email',
    },
    {
      label: 'Address',
      value: 'address',
    },
  ];
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(clearData());
    if (term.replace(' ', '').length === 0) {
      toast.warning('Please enter a value in search box');
    } else {
      dispatch(getSearchTerm({ searchTerm: term, searchOption: option }));
      const splitWords = term.split(' ');
      const newFilter = [];
      splitWords.forEach((word) => {
        if (word) {
          newFilter.push({ [option]: { wildcard: `${word}*` } });
        }
      });

      getUserDetails({
        variables: {
          filter: { and: newFilter },
          limit: 25,
        },
      });
    }
  };
  return (
    <>
      <div className="flex justify-center">
        <form onSubmit={submitHandler} className=" flex">
          <div className="flex flex-col border border-gray-300 ">
            <span className="text-center">Search By</span>
            <select
              id="search-option"
              value={option}
              onChange={(e) => setOption(e.target.value)}
              className=" rounded focus-outline-none focus:border-gray-800">
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="relative">
            <input
              type="text"
              name=""
              id="search-term"
              value={term}
              className="border border-6 w-[550px] p-3 h-full  border-gray-300 rounded focus:outline-0 focus:border-gray-800"
              onChange={(e) => {
                setTerm(e.target.value);
              }}
            />
            {isClose && (
              <img
                src={close}
                width="24px"
                height="44px"
                className="absolute right-1 top-1/2 -translate-y-1/2"
                alt=""
                onClick={() => setTerm('')}
              />
            )}
          </div>
          <button
            className="border p-2  bg-gray-800 rounded text-white font-prompt-semiBold text-lg disabled:bg-dn-nav-color"
            disabled={loading}>
            {loading ? 'loading' : 'Search'}
          </button>
        </form>
      </div>
      {loading ? (
        <Loading type="bars" color="d31c1e" text="Fetching Data.." />
      ) : (
        ''
      )}
    </>
  );
};

export default Search;
