import { useMutation, gql } from '@apollo/client';
import { createAjcoperationsadmindeliveryscheduleratecode } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import { GET_DELIVERY_SCHEDULE } from '../../queries/useGetDeliverySchedule';
export const useCreateDeliverySchedule = (variables) => {
  const CREATE_DELIVERY_SCHEDULE = gql`
    ${createAjcoperationsadmindeliveryscheduleratecode}
  `;

  const [
    createDeliverySchedule,
    {
      error: deliveryError,
      data: createdDeliveryData,
      loading: createdDdeliveryLoading,
    },
  ] = useMutation(CREATE_DELIVERY_SCHEDULE, {
    variables: variables,
    refetchQueries: [
      {
        query: GET_DELIVERY_SCHEDULE,
        variables: { limit: 10000 },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Created Successfully');
    },
    onError: (error) => {
      toast.error(
        'Oops! An error occurred. Please refresh the page and try again.'
      );
    },
  });
  return {
    createDeliverySchedule,
    createdDdeliveryLoading,
    deliveryError,
    createdDeliveryData,
  };
};
