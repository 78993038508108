import { useMutation, gql } from '@apollo/client';
import { updateAjcoperationsadminsubscriptionoverride } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const useUpdateAppointment = (variables) => {
  const UPDATE_APPOINTMENT = gql`
    ${updateAjcoperationsadminsubscriptionoverride}
  `;
  const [
    updateAppointment,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(
    UPDATE_APPOINTMENT,
    { variables: variables },
    {
      onCompleted: () => {
        toast.success('Updated Successfully');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
    }
  );

  return { updateAppointment, updateLoading, updateError, updateData };
};
