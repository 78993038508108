import React from 'react';

const EditableRow = ({
  rowNames,
  formData,
  handleEditFormChange,
  handleCancelClick,
  handleEditFormSubmit,
}) => {
  return (
    <tr className="h-[57px] odd:bg-white">
      {rowNames.map((row) => (
        <td
          className="border border-gray-300 font-prompt-regular h-10 "
          key={row}
        >
          <input
            className="h-full w-full"
            type="text"
            name={row}
            value={formData[row] ? formData[row] : 'NA'}
            key={row}
            onChange={handleEditFormChange}
          />
        </td>
      ))}
      <td className="border border-gray-300 font-prompt-regular h-10">
        <div className="flex gap-2 p-2 items-center justify-center">
          <button
            type="submit"
            className="bg-gray-800  text-white px-2"
            onClick={handleEditFormSubmit}
          >
            Save
          </button>
          <button
            type="button"
            className="bg-gray-800  text-white px-2"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </td>
    </tr>
  );
};

export default EditableRow;
