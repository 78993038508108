import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: true,
  currentPage: 0,
  total: 0,
};

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    getDeliveryData: (state, { payload }) => {
      state.data = payload.deliveryData;
      state.loading = false;
      state.total = payload.deliveryData.length;
    },
    nextPage: (state) => {
      state.currentPage += 1;
    },
    previousPage: (state) => {
      state.currentPage -= 1;
    },
    setCurrentPage: (state) => {
      state.currentPage = 0;
    },
  },
});

export default deliverySlice.reducer;

export const { getDeliveryData, nextPage, previousPage, setCurrentPage } =
  deliverySlice.actions;
