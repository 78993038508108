import React from 'react';

const ConversionOptions = ({ data }) => {
  const billingKey = [
    { primaryratecode: 'Primary Rate Code' },
    { primarymonthlyrate: 'Primary Monthly Rate' },
    { primaryoffer: 'Primary Offer' },
    { primarytoprate: 'Primary Top Rate' },
    { secondaryratecode: 'Secondary Rate Code' },
    { secondarymonthlyrate: 'Secondary monthly Rate' },
    { secondayoffer: 'Seconday Offer' },
    { secondarytoprate: 'Seconday Top Rate' },
    { lastresortratecode: 'Last Resort Rate Code' },
    { lastresortmonthlyrate: 'Last Resort Monthly Rate' },
    { lastresortoffer: 'Last Resort Offer' },
    { lastresorttoprate: 'Last Resort Top Rate' },
  ];
  return (
    <div className=" h-[300px] shadow-lg py-4 px-7  rounded min-h-[225px]">
      <h1 className="text-xl font-bold mb-4 ">Conversion Data</h1>

      <div className="flex justify-between mb-10">
        <div>
          <h4 className="inline mr-1  font-semibold ">
            Communication Status:{' '}
          </h4>
          <h4 className="inline">{data?.communicationstatus}</h4>
        </div>
        <div>
          <h4 className="inline mr-1  font-semibold ">Delivery Type: </h4>
          <h4 className="inline">{data?.deliverytype}</h4>
        </div>
        <div>
          <h4 className="inline mr-1  font-semibold ">
            Target Conversion Date:
          </h4>
          <h4 className="inline">{data?.conversionstatus || 'NA'}</h4>
        </div>
        <div>
          <h4 className="inline mr-1  font-semibold ">Conversion Status: </h4>
          <h4 className="inline">{data?.conversionstatus || 'NA'}</h4>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-4 ">
          <h4 className="inline mr-1  font-semibold ">Primary Rate Code:</h4>
          <div>
            <h4 className="inline">Rate per Month: </h4>
            <h4 className="inline">{data?.primarymonthlyrate || 'NA'}</h4>
          </div>
          <div>
            <h4 className="inline">Rate Code: </h4>
            <h4 className="inline">{data?.primaryratecode || 'NA'}</h4>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <h4 className="inline mr-1  font-semibold ">Secondary Rate Code</h4>
          <div>
            <h4 className="inline">Rate per Month: </h4>
            <h4 className="inline">{data?.secondarymonthlyrate || 'NA'}</h4>
          </div>
          <div>
            <h4 className="inline">Rate Code: </h4>
            <h4 className="inline">{data?.secondaryratecode || 'NA'}</h4>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <h4 className="inline mr-1  font-semibold ">Last Resort Rate Code </h4>
          <div>
            <h4 className="inline">Rate per Month: </h4>
            <h4 className="inline">{data?.lastresortmonthlyrate || 'NA'}</h4>
          </div>
          <div>
            <h4 className="inline">Rate Code: </h4>
            <h4 className="inline">{data?.lastresortratecode || 'NA'}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionOptions;
