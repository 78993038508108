import { useLazyQuery, gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { listAjcoperationsadminoffers } from '../../graphql/queries';
import { getOfferData } from '../../redux/reducers/newOffer';

export const GET_NEW_START_OFFER = gql`
  ${listAjcoperationsadminoffers}
`;
export const useGetNewStartOffer = () => {
  const dispatch = useDispatch();

  const [
    getNewStartOffer,
    { error: getError, data: getData, loading: getLoading },
  ] = useLazyQuery(GET_NEW_START_OFFER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
    variables: {
      limit: 1000,
    },
    onCompleted: (data) => {
      const results = data.listAjcoperationsadminoffers.items.map((element) => {
        return { ...element, unique: uuidv4() };
      });
      dispatch(
        getOfferData({
          offerData: results,
          total: data.listAjcoperationsadminoffers.items.length,
        })
      );
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  return {
    getNewStartOffer,
    getError,
    getData,
    getLoading,
  };
};
