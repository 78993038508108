import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Header from '../components/Header/default';
import Home from './Home';
import Profile from './Profile';
import Delivery from './Delivery';
import DeliveryZip from './DeliveryZip';
import ProtectedRoute from './ProtectedRoute';
import Resources from './Resources';
import Offers from './Offers';
import Templates from './Templates';
import Unauthorized from './Unauthorized';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmin } from '../redux/reducers/admin';
import { useGetNewStartOffer } from '../queries/queries/useGetNewStartOffer';
import { getCurrentAuth } from '../redux/reducers/auth';

const AppLayout = () => {
  const dispatch = useDispatch();
  const { isAdmin, adminButton } = useSelector((store) => store.admin);
  const { currentUser } = useSelector((store) => store.auth);
  const { getNewStartOffer, error, data, loading } = useGetNewStartOffer();
  const { pathname } = useLocation();

  useEffect(() => {
    getNewStartOffer();
    const checkAdmin = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload['cognito:groups'];

      if (groups) {
        dispatch(getCurrentAuth(groups[0]));
      } else {
        dispatch(getCurrentAuth('noGroup'));
      }
      if (groups && groups.includes('admin')) {
        dispatch(getAdmin(true));
        dispatch(getCurrentAuth({ admin: true }));
      } else {
        dispatch(getAdmin(false));
      }
    };
    checkAdmin();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      {currentUser === 'noGroup' ? (
        <Unauthorized />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<Profile />} />
          <Route
            path="/delivery"
            element={
              <ProtectedRoute user={isAdmin}>
                <Delivery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/deliveryzip"
            element={
              <ProtectedRoute user={isAdmin}>
                <DeliveryZip />
              </ProtectedRoute>
            }
          />
          <Route
            path="/templates"
            element={
              <ProtectedRoute user={isAdmin && adminButton}>
                <Templates />
              </ProtectedRoute>
            }
          />

          <Route path="/offers" element={<Offers />} />
          <Route path="/resources" element={<Resources />} />
        </Routes>
      )}
    </>
  );
};

export default AppLayout;
