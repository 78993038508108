import { Link } from 'react-router-dom';

import React from 'react';

const BackButton = () => {
  return (
    <Link
      to="/"
      className="w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-center ml-auto mb-10"
    >
      Back
    </Link>
  );
};

export default BackButton;
