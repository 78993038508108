import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const userScheduleSlice = createSlice({
  name: 'userSchedule',
  initialState,
  reducers: {
    getSchedule: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export default userScheduleSlice.reducer;
export const { getSchedule } = userScheduleSlice.actions;
