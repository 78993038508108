import { gql, useMutation } from '@apollo/client';
import { deleteAjcoperationsadmindeliveryscheduleratecode } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import { GET_DELIVERY_SCHEDULE } from '../../queries/useGetDeliverySchedule';

export const useDeleteDeliverySchedule = () => {
  const DELETE_DELIVERY_SCHEDULE = gql`
    ${deleteAjcoperationsadmindeliveryscheduleratecode}
  `;

  const [
    deleteDeliverySchedule,
    {
      error: deleteDeliveryError,
      data: deleteDeliveryData,
      loading: deleteDeliveryLoading,
    },
  ] = useMutation(DELETE_DELIVERY_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_DELIVERY_SCHEDULE,
        variables: { limit: 10000 },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Deleted Successfully');
    },
    onError: (error) => {
      toast.error('Something went wrong ');
    },
  });

  return {
    deleteDeliverySchedule,
    deleteDeliveryError,
    deleteDeliveryData,
    deleteDeliveryLoading,
  };
};
