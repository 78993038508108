import { useLazyQuery, gql } from '@apollo/client';
import { listAjcoperationsadmindeliveryscheduleratecodes } from '../../graphql/queries';
import { useDispatch } from 'react-redux';
import { getDeliveryData } from '../../redux/reducers/deliv';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
export const GET_DELIVERY_SCHEDULE = gql`
  ${listAjcoperationsadmindeliveryscheduleratecodes}
`;
export const useGetDeliverySchedule = () => {
  const dispatch = useDispatch();
  const [
    getDeliverySchedule,
    { loading: deliveryLoading, error, data: deliveryData },
  ] = useLazyQuery(GET_DELIVERY_SCHEDULE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
    variables: {
      limit: 1000,
    },
    onError: (error) => {
      toast.error('Something went wrong');
    },

    onCompleted: (data) => {
      const results =
        data.listAjcoperationsadmindeliveryscheduleratecodes.items.map(
          (element) => {
            return { ...element, unique: uuidv4() };
          }
        );
      dispatch(
        getDeliveryData({
          deliveryData: results,
          deliveryLength:
            data.listAjcoperationsadmindeliveryscheduleratecodes.items.length,
        })
      );
    },
  });
  return { getDeliverySchedule, deliveryLoading, deliveryData };
};
