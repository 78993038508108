import React from 'react';
import UserProfile from '../../components/UserProfile/default';

const index = () => {
  return (
    <div className="pt-10 max-w-[1600px] mx-auto">
      <h1 className="text-2xl text-center font-extrabold pb-10">
        User Profile
      </h1>
      <UserProfile />
    </div>
  );
};

export default index;
