import { useState, useEffect } from 'react';
import { useCreateAdminTemplate } from '../../../queries/mutation/adminTemplates/useCreateAdminTemplate';
import { toast } from 'react-toastify';
import Loading from '../../HelperComponents/Loading';
import { useGetAdminTemplates } from '../../../queries/queries/useGetAdminTemplates';

const CreateTemplate = () => {
  const [template, setTemplate] = useState({
    id: '',
    title: '',
    description: '',
    script: '',
    instructions: '',
  });
  const { getAdminTemplates } = useGetAdminTemplates();
  const submitHandler = (e) => {
    e.preventDefault();
    for (let key in template) {
      if (!template[key]) {
        toast.warn('Please fill all the fields');
        return;
      }
    }
    createAdminTemplate({
      variables: {
        input: template,
      },
    });
    setTemplate({
      id: '',
      title: '',
      description: '',
      script: '',
      instructions: '',
    });
  };
  const onChangeHandler = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === 'id') {
      value = Number(value);
    }
    setTemplate({ ...template, [key]: value });
  };

  const {
    createAdminTemplate,
    createAdminTemplateData,
    createAdminTemplateLoading,
  } = useCreateAdminTemplate();

  useEffect(() => {
    if (createAdminTemplateData) {
      getAdminTemplates();
    }
  }, [createAdminTemplateData, getAdminTemplates]);
  if (createAdminTemplateLoading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }
  return (
    <div>
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <label htmlFor="id">ID</label>
        <input
          type="text"
          className="border-2 w-1/4 p-2"
          name="id"
          id="id"
          value={template.id}
          onChange={(e) => onChangeHandler(e)}
        />

        <label htmlFor="title">Title</label>
        <input
          type="text"
          className="border-2 w-1/4 p-2"
          name="title"
          id="title"
          value={template.title}
          onChange={(e) => onChangeHandler(e)}
        />
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          id="description"
          className="border-2 w-1/2 whitespace-pre"
          cols="30"
          value={template.description}
          onChange={(e) => onChangeHandler(e)}
          rows="10"></textarea>
        <label htmlFor="script">Script</label>
        <textarea
          name="script"
          id="script"
          className="border-2 w-1/2 whitespace-pre"
          value={template.script}
          onChange={(e) => onChangeHandler(e)}
          cols="30"
          rows="10"></textarea>
        <label htmlFor="instructions">Instructions</label>
        <textarea
          name="instructions"
          id="instructions"
          className="border-2 w-1/2 whitespace-pre"
          value={template.instructions}
          onChange={(e) => onChangeHandler(e)}
          cols="30"
          rows="10"></textarea>

        <div className="flex">
          <button className="border p-2 w-40 h-12 rounded font-prompt-semiBold text-l disabled:bg-dn-nav-color text-white bg-gray-800">
            Add Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTemplate;
