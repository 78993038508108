import { useQuery, gql } from '@apollo/client';
import { getAjcoperationsadminemaillogin } from '../../graphql/queries';

export const GET_ADMIN_EMAIL_LOGINS = gql`
  ${getAjcoperationsadminemaillogin}
`;

export const useGetAdminMailLogins = (variables) => {
  const {
    data: EmailData,
    error: emailError,
    loading: errorLoading,
  } = useQuery(GET_ADMIN_EMAIL_LOGINS, {
    variables,
  });
  return {
    EmailData,
    emailError,
    errorLoading,
  };
};
