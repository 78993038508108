import { Parser } from 'html-to-react';

import { useGetAdminHtmlPage } from '../../queries/queries/useGetAdminHtmlPage';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../HelperComponents/Loading';
import { useUpdateHtmlPages } from '../../queries/mutation/adminHtmlPages/useUpdateHtmlPages';

const RawHtml = ({ page }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { resourceContent, homeContent, loading } = useSelector(
    (store) => store.adminHtml
  );
  const [content, SetContent] = useState(
    page === 'resource' ? resourceContent : homeContent
  );

  const { adminButton, isAdmin } = useSelector((store) => store.admin);

  const { updateHtmlPages, updateData, updateError, updateLoading } =
    useUpdateHtmlPages();
  const { getHtmlPage, getError, getData, getLoading } = useGetAdminHtmlPage();

  const changeHandler = (e) => {
    SetContent(e.target.value);
  };

  const editHandler = () => {
    setIsEdit(!isEdit);
  };
  const updateHandler = async () => {
    if (content.length === 0) {
      toast.warning('Please enter html content');
      return;
    }

    await updateHtmlPages({
      variables: {
        input: {
          pagename: page,
          pagecontent: content,
        },
      },
    });
    SetContent('');
    setIsEdit(false);
  };
  useEffect(() => {
    getHtmlPage();
  }, []);
  useEffect(() => {
    if (updateData) {
      getHtmlPage();
    }
  }, [updateData, getHtmlPage]);

  const cancelHandler = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    if (page === 'resource') {
      SetContent(resourceContent);
    } else SetContent(homeContent);
  }, [resourceContent, homeContent, page]);
  if (getLoading || updateLoading || loading) {
    return <Loading type="bars" color="d31c1e" text="Loading Content..." />;
  }

  return (
    <div>
      {adminButton && isAdmin && (
        <button
          className="border p-1  bg-gray-800 rounded text-white font-prompt-regular text-sm disabled:bg-dn-nav-color block ml-auto"
          onClick={editHandler}>
          {!isEdit ? 'Edit' : 'Cancel'}
        </button>
      )}
      <div className="no-tailwind">
        {Parser().parse(page === 'resource' ? resourceContent : homeContent)}
      </div>

      {isEdit && (
        <div className="w-full h-full fixed z-2  bg-black bg-opacity-40 left-0 top-0  flex items-center justify-center">
          <div className="bg-white py-2 px-10 rounded ">
            <h3 className="text-center text-gray-800 text-lg font-semibold">
              Paste the html below
            </h3>
            <textarea
              name=""
              id=""
              cols="60"
              rows="30"
              className="border border-black resize"
              onChange={changeHandler}
              value={content}
            />
            <div className="flex justify-between">
              <button
                className="border p-1  bg-gray-800 rounded text-white font-prompt-regular text-sm disabled:bg-dn-nav-color block "
                onClick={cancelHandler}>
                Cancel
              </button>
              <button
                className="border p-1  bg-gray-800 rounded text-white font-prompt-regular text-sm disabled:bg-dn-nav-color block "
                onClick={updateHandler}>
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RawHtml;
