/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAjcoperationsadminsubscriptions = /* GraphQL */ `
  query GetAjcoperationsadminsubscriptions($subscriptionid: String!) {
    getAjcoperationsadminsubscriptions(subscriptionid: $subscriptionid) {
      subscriptionid
      subscriber
      area
      occupantid
      addressid
      phone
      autopay
      deliveryscheduleid
      laststartdate
      subscriptiontype
      monthlyrate
      originalstartdate
      billingmethod
      customername
      address
      city
      state
      zipcode
      districtid
      routeid
      futurestop
      expiredate
      email
      registered
      engaged
      newdeliveryschedule
      primaryratecode
      primarymonthlyrate
      primaryoffer
      primarytoprate
      secondaryratecode
      secondarymonthlyrate
      secondayoffer
      secondarytoprate
      lastresortratecode
      lastresortmonthlyrate
      lastresortoffer
      lastresorttoprate
      deliverytype
      communicationstatus
      substatus
      ipadstatus
      ipadserialnumber
      futuremovetype
      delivscheddate
      newdeliveryscheduleid
      newratecodeid
      newmonthlyrate
      zonedesignation
      subscribersource
      calendlylink
      updateddate
      override {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      conversionstatus {
        subscriptionid
        conversionstatus
        createdAt
        updatedAt
        __typename
      }
      appointmentdata {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      lowermybill {
        subscriptionid
        capid
        circsystem
        siteid
        productid
        starrating
        deliveryscheduleid
        lastpaidrate
        lastbilledrate
        upgradedeliveryscheduleid
        fullupgradedeliveryscheduleid
        downgrade1deliveryscheduleid
        downgrade2deliveryscheduleid
        digitaldeliveryscheduleid
        ratecodeidrank1
        ratecodeidrank2
        ratecodeidrank3
        ratecodeidrank4
        ratecodeidrank5
        downgradeoption1ratecodeid
        downgradeoption2ratecodeid
        upgradeoptionratecodeid
        fullupgradeoptionratecodeid
        digitalratecodeid
        digital2ratecodeid
        monthlyrate1
        monthlyrate2
        monthlyrate3
        monthlyrate4
        monthlyrate5
        downgradeoption1rate
        downgradeoption2rate
        upgradeoptionrate
        fullupgradeoptionrate
        digitalrate
        digital2rate
        changetolastpaidrate1
        changetolastpaidrate2
        changetolastpaidrate3
        changetolastpaidrate4
        changetolastpaidrate5
        changetolastpaidratedowngrade1
        changetolastpaidratedowngrade2
        changetolastpaidrateupgrade
        changetolastpaidratefullupgrade
        changetolastpaidratedigital
        changetolastpaidratedigital2
        changetolastbilledrate1
        changetolastbilledrate2
        changetolastbilledrate3
        changetolastbilledrate4
        changetolastbilledrate5
        changetolastbilledratedowngrade1
        changetolastbilledratedowngrade2
        changetolastbilledrateupgrade
        changetolastbilledratefullupgrade
        changetolastbilledratedigital
        changetolastbilledratedigital2
        discountpercent1
        discountpercent2
        discountpercent3
        discountpercent4
        discountpercent5
        discountpercentdowngradeoption1
        discountpercentdowngradeoption2
        discountpercentupdowngradeoption
        discountpercentfullupdowngradeoption
        discountpercentdigital
        discountpercentdigital2
        valuation
        createdAt
        updatedAt
        __typename
      }
      emaillogin {
        subscriptionid
        registeredusers
        engagedactiveusers
        usageepaper
        usagemysite
        ncemail1
        ncemail2
        mg2email1
        mg2email2
        mg2email3
        mg2email4
        mg2email5
        mg2email6
        mg2email7
        mg2email8
        mg2email1lastusage
        mg2email2lastusage
        mg2email3lastusage
        mg2email4lastusage
        mg2email5lastusage
        mg2email6lastusage
        mg2email7lastusage
        mg2email8lastusage
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminsubscriptions = /* GraphQL */ `
  query ListAjcoperationsadminsubscriptions(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminsubscriptions(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByPhoneNumber = /* GraphQL */ `
  query SearchByPhoneNumber(
    $phone: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByPhoneNumber(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByCustomerName = /* GraphQL */ `
  query SearchByCustomerName(
    $customername: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByCustomerName(
      customername: $customername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByAddress = /* GraphQL */ `
  query SearchByAddress(
    $address: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByAddress(
      address: $address
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByEmailAddress = /* GraphQL */ `
  query SearchByEmailAddress(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByEmailAddress(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByUpdatedDate = /* GraphQL */ `
  query SearchByUpdatedDate(
    $updateddate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminsubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByUpdatedDate(
      updateddate: $updateddate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAjcoperationsadminsubscriptions = /* GraphQL */ `
  query SearchAjcoperationsadminsubscriptions(
    $filter: SearchableajcoperationsadminsubscriptionsFilterInput
    $sort: [SearchableajcoperationsadminsubscriptionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableajcoperationsadminsubscriptionsAggregationInput]
  ) {
    searchAjcoperationsadminsubscriptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        subscriptionid
        subscriber
        area
        occupantid
        addressid
        phone
        autopay
        deliveryscheduleid
        laststartdate
        subscriptiontype
        monthlyrate
        originalstartdate
        billingmethod
        customername
        address
        city
        state
        zipcode
        districtid
        routeid
        futurestop
        expiredate
        email
        registered
        engaged
        newdeliveryschedule
        primaryratecode
        primarymonthlyrate
        primaryoffer
        primarytoprate
        secondaryratecode
        secondarymonthlyrate
        secondayoffer
        secondarytoprate
        lastresortratecode
        lastresortmonthlyrate
        lastresortoffer
        lastresorttoprate
        deliverytype
        communicationstatus
        substatus
        ipadstatus
        ipadserialnumber
        futuremovetype
        delivscheddate
        newdeliveryscheduleid
        newratecodeid
        newmonthlyrate
        zonedesignation
        subscribersource
        calendlylink
        updateddate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAjcoperationsadminappointmentdata = /* GraphQL */ `
  query GetAjcoperationsadminappointmentdata($subscriptionid: String!) {
    getAjcoperationsadminappointmentdata(subscriptionid: $subscriptionid) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminappointmentdata = /* GraphQL */ `
  query ListAjcoperationsadminappointmentdata(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminappointmentdataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminappointmentdata(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminconversionstatus = /* GraphQL */ `
  query GetAjcoperationsadminconversionstatus($subscriptionid: String!) {
    getAjcoperationsadminconversionstatus(subscriptionid: $subscriptionid) {
      subscriptionid
      conversionstatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminconversionstatuses = /* GraphQL */ `
  query ListAjcoperationsadminconversionstatuses(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminconversionstatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminconversionstatuses(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        conversionstatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminsubscriptionoverride = /* GraphQL */ `
  query GetAjcoperationsadminsubscriptionoverride($subscriptionid: String!) {
    getAjcoperationsadminsubscriptionoverride(subscriptionid: $subscriptionid) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminsubscriptionoverrides = /* GraphQL */ `
  query ListAjcoperationsadminsubscriptionoverrides(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminsubscriptionoverrideFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminsubscriptionoverrides(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadmindeliveryschedulebyzipcode = /* GraphQL */ `
  query GetAjcoperationsadmindeliveryschedulebyzipcode($zipcode: String!) {
    getAjcoperationsadmindeliveryschedulebyzipcode(zipcode: $zipcode) {
      zipcode
      area
      deliverytype
      newdeliveryschedule
      calendlylink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadmindeliveryschedulebyzipcodes = /* GraphQL */ `
  query ListAjcoperationsadmindeliveryschedulebyzipcodes(
    $zipcode: String
    $filter: ModelAjcoperationsadmindeliveryschedulebyzipcodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadmindeliveryschedulebyzipcodes(
      zipcode: $zipcode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        zipcode
        area
        deliverytype
        newdeliveryschedule
        calendlylink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadmindeliveryscheduleratecode = /* GraphQL */ `
  query GetAjcoperationsadmindeliveryscheduleratecode(
    $newdeliveryscheduleipadcustomer: String!
    $currentmonthlyratemin: String!
  ) {
    getAjcoperationsadmindeliveryscheduleratecode(
      newdeliveryscheduleipadcustomer: $newdeliveryscheduleipadcustomer
      currentmonthlyratemin: $currentmonthlyratemin
    ) {
      newdeliveryscheduleipadcustomer
      newdeliveryschedule
      ipadcustomer
      currentmonthlyratemin
      currentmonthlyratemax
      newmonthlyrate
      newratecode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadmindeliveryscheduleratecodes = /* GraphQL */ `
  query ListAjcoperationsadmindeliveryscheduleratecodes(
    $newdeliveryscheduleipadcustomer: String
    $currentmonthlyratemin: ModelStringKeyConditionInput
    $filter: ModelAjcoperationsadmindeliveryscheduleratecodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadmindeliveryscheduleratecodes(
      newdeliveryscheduleipadcustomer: $newdeliveryscheduleipadcustomer
      currentmonthlyratemin: $currentmonthlyratemin
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        newdeliveryscheduleipadcustomer
        newdeliveryschedule
        ipadcustomer
        currentmonthlyratemin
        currentmonthlyratemax
        newmonthlyrate
        newratecode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminhtmlpages = /* GraphQL */ `
  query GetAjcoperationsadminhtmlpages($pagename: String!) {
    getAjcoperationsadminhtmlpages(pagename: $pagename) {
      pagename
      pagecontent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminhtmlpages = /* GraphQL */ `
  query ListAjcoperationsadminhtmlpages(
    $pagename: String
    $filter: ModelAjcoperationsadminhtmlpagesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminhtmlpages(
      pagename: $pagename
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pagename
        pagecontent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminoffers = /* GraphQL */ `
  query GetAjcoperationsadminoffers($id: ID!) {
    getAjcoperationsadminoffers(id: $id) {
      id
      active
      offercreatedate
      offerstartdate
      offerexpiredate
      site
      publication
      offercode
      weeklyprice
      totalprice
      activationfee
      offername
      offerdescriptiion
      campaigncode
      source
      subsource
      deliveryschedule
      ratetype
      ratecode
      merchandisecode
      defaultoffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminoffers = /* GraphQL */ `
  query ListAjcoperationsadminoffers(
    $id: ID
    $filter: ModelAjcoperationsadminoffersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminoffers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        active
        offercreatedate
        offerstartdate
        offerexpiredate
        site
        publication
        offercode
        weeklyprice
        totalprice
        activationfee
        offername
        offerdescriptiion
        campaigncode
        source
        subsource
        deliveryschedule
        ratetype
        ratecode
        merchandisecode
        defaultoffer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminlowermybill = /* GraphQL */ `
  query GetAjcoperationsadminlowermybill($subscriptionid: String!) {
    getAjcoperationsadminlowermybill(subscriptionid: $subscriptionid) {
      subscriptionid
      capid
      circsystem
      siteid
      productid
      starrating
      deliveryscheduleid
      lastpaidrate
      lastbilledrate
      upgradedeliveryscheduleid
      fullupgradedeliveryscheduleid
      downgrade1deliveryscheduleid
      downgrade2deliveryscheduleid
      digitaldeliveryscheduleid
      ratecodeidrank1
      ratecodeidrank2
      ratecodeidrank3
      ratecodeidrank4
      ratecodeidrank5
      downgradeoption1ratecodeid
      downgradeoption2ratecodeid
      upgradeoptionratecodeid
      fullupgradeoptionratecodeid
      digitalratecodeid
      digital2ratecodeid
      monthlyrate1
      monthlyrate2
      monthlyrate3
      monthlyrate4
      monthlyrate5
      downgradeoption1rate
      downgradeoption2rate
      upgradeoptionrate
      fullupgradeoptionrate
      digitalrate
      digital2rate
      changetolastpaidrate1
      changetolastpaidrate2
      changetolastpaidrate3
      changetolastpaidrate4
      changetolastpaidrate5
      changetolastpaidratedowngrade1
      changetolastpaidratedowngrade2
      changetolastpaidrateupgrade
      changetolastpaidratefullupgrade
      changetolastpaidratedigital
      changetolastpaidratedigital2
      changetolastbilledrate1
      changetolastbilledrate2
      changetolastbilledrate3
      changetolastbilledrate4
      changetolastbilledrate5
      changetolastbilledratedowngrade1
      changetolastbilledratedowngrade2
      changetolastbilledrateupgrade
      changetolastbilledratefullupgrade
      changetolastbilledratedigital
      changetolastbilledratedigital2
      discountpercent1
      discountpercent2
      discountpercent3
      discountpercent4
      discountpercent5
      discountpercentdowngradeoption1
      discountpercentdowngradeoption2
      discountpercentupdowngradeoption
      discountpercentfullupdowngradeoption
      discountpercentdigital
      discountpercentdigital2
      valuation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminlowermybills = /* GraphQL */ `
  query ListAjcoperationsadminlowermybills(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminlowermybillFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminlowermybills(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        capid
        circsystem
        siteid
        productid
        starrating
        deliveryscheduleid
        lastpaidrate
        lastbilledrate
        upgradedeliveryscheduleid
        fullupgradedeliveryscheduleid
        downgrade1deliveryscheduleid
        downgrade2deliveryscheduleid
        digitaldeliveryscheduleid
        ratecodeidrank1
        ratecodeidrank2
        ratecodeidrank3
        ratecodeidrank4
        ratecodeidrank5
        downgradeoption1ratecodeid
        downgradeoption2ratecodeid
        upgradeoptionratecodeid
        fullupgradeoptionratecodeid
        digitalratecodeid
        digital2ratecodeid
        monthlyrate1
        monthlyrate2
        monthlyrate3
        monthlyrate4
        monthlyrate5
        downgradeoption1rate
        downgradeoption2rate
        upgradeoptionrate
        fullupgradeoptionrate
        digitalrate
        digital2rate
        changetolastpaidrate1
        changetolastpaidrate2
        changetolastpaidrate3
        changetolastpaidrate4
        changetolastpaidrate5
        changetolastpaidratedowngrade1
        changetolastpaidratedowngrade2
        changetolastpaidrateupgrade
        changetolastpaidratefullupgrade
        changetolastpaidratedigital
        changetolastpaidratedigital2
        changetolastbilledrate1
        changetolastbilledrate2
        changetolastbilledrate3
        changetolastbilledrate4
        changetolastbilledrate5
        changetolastbilledratedowngrade1
        changetolastbilledratedowngrade2
        changetolastbilledrateupgrade
        changetolastbilledratefullupgrade
        changetolastbilledratedigital
        changetolastbilledratedigital2
        discountpercent1
        discountpercent2
        discountpercent3
        discountpercent4
        discountpercent5
        discountpercentdowngradeoption1
        discountpercentdowngradeoption2
        discountpercentupdowngradeoption
        discountpercentfullupdowngradeoption
        discountpercentdigital
        discountpercentdigital2
        valuation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationscustomerissues = /* GraphQL */ `
  query GetAjcoperationscustomerissues($id: ID!, $subscriptionid: String!) {
    getAjcoperationscustomerissues(id: $id, subscriptionid: $subscriptionid) {
      id
      subscriptionid
      csremail
      customeremail
      issue
      issuedescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationscustomerissues = /* GraphQL */ `
  query ListAjcoperationscustomerissues(
    $id: ID
    $subscriptionid: ModelStringKeyConditionInput
    $filter: ModelAjcoperationscustomerissuesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationscustomerissues(
      id: $id
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        subscriptionid
        csremail
        customeremail
        issue
        issuedescription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBySubscriptionId = /* GraphQL */ `
  query SearchBySubscriptionId(
    $subscriptionid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationscustomerissuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchBySubscriptionId(
      subscriptionid: $subscriptionid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionid
        csremail
        customeremail
        issue
        issuedescription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminemaillogin = /* GraphQL */ `
  query GetAjcoperationsadminemaillogin($subscriptionid: String!) {
    getAjcoperationsadminemaillogin(subscriptionid: $subscriptionid) {
      subscriptionid
      registeredusers
      engagedactiveusers
      usageepaper
      usagemysite
      ncemail1
      ncemail2
      mg2email1
      mg2email2
      mg2email3
      mg2email4
      mg2email5
      mg2email6
      mg2email7
      mg2email8
      mg2email1lastusage
      mg2email2lastusage
      mg2email3lastusage
      mg2email4lastusage
      mg2email5lastusage
      mg2email6lastusage
      mg2email7lastusage
      mg2email8lastusage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminemaillogins = /* GraphQL */ `
  query ListAjcoperationsadminemaillogins(
    $subscriptionid: String
    $filter: ModelAjcoperationsadminemailloginFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminemaillogins(
      subscriptionid: $subscriptionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        registeredusers
        engagedactiveusers
        usageepaper
        usagemysite
        ncemail1
        ncemail2
        mg2email1
        mg2email2
        mg2email3
        mg2email4
        mg2email5
        mg2email6
        mg2email7
        mg2email8
        mg2email1lastusage
        mg2email2lastusage
        mg2email3lastusage
        mg2email4lastusage
        mg2email5lastusage
        mg2email6lastusage
        mg2email7lastusage
        mg2email8lastusage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadminerecommendations = /* GraphQL */ `
  query GetAjcoperationsadminerecommendations(
    $subscriptionid: String!
    $recommendationid: String!
  ) {
    getAjcoperationsadminerecommendations(
      subscriptionid: $subscriptionid
      recommendationid: $recommendationid
    ) {
      subscriptionid
      recommendationid
      score
      displayrank
      isactive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadminerecommendations = /* GraphQL */ `
  query ListAjcoperationsadminerecommendations(
    $subscriptionid: String
    $recommendationid: ModelStringKeyConditionInput
    $filter: ModelAjcoperationsadminerecommendationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadminerecommendations(
      subscriptionid: $subscriptionid
      recommendationid: $recommendationid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        subscriptionid
        recommendationid
        score
        displayrank
        isactive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByRecommendationsSubId = /* GraphQL */ `
  query SearchByRecommendationsSubId(
    $subscriptionid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcoperationsadminerecommendationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByRecommendationsSubId(
      subscriptionid: $subscriptionid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        subscriptionid
        recommendationid
        score
        displayrank
        isactive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcoperationsadmineemailtemplates = /* GraphQL */ `
  query GetAjcoperationsadmineemailtemplates($id: String!) {
    getAjcoperationsadmineemailtemplates(id: $id) {
      id
      title
      description
      script
      instructions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcoperationsadmineemailtemplates = /* GraphQL */ `
  query ListAjcoperationsadmineemailtemplates(
    $id: String
    $filter: ModelAjcoperationsadmineemailtemplatesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcoperationsadmineemailtemplates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        script
        instructions
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjclinkage = /* GraphQL */ `
  query GetAjclinkage($user_id: String!, $subscription_id: String!) {
    getAjclinkage(user_id: $user_id, subscription_id: $subscription_id) {
      user_id
      subscription_id
      email
      piano_user_id
      owner_indc
      registration_date
      linkage_date
      linkage_removed_indc
      naviga_churn_indc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjclinkages = /* GraphQL */ `
  query ListAjclinkages(
    $user_id: String
    $subscription_id: ModelStringKeyConditionInput
    $filter: ModelAjclinkageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjclinkages(
      user_id: $user_id
      subscription_id: $subscription_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByUserId = /* GraphQL */ `
  query SearchByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajclinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBySubId = /* GraphQL */ `
  query SearchBySubId(
    $subscription_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajclinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchBySubId(
      subscription_id: $subscription_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByEmail = /* GraphQL */ `
  query SearchByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajclinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByPianoUserId = /* GraphQL */ `
  query SearchByPianoUserId(
    $piano_user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajclinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByPianoUserId(
      piano_user_id: $piano_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByNavigaChurnIndc = /* GraphQL */ `
  query SearchByNavigaChurnIndc(
    $naviga_churn_indc: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajclinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByNavigaChurnIndc(
      naviga_churn_indc: $naviga_churn_indc
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        subscription_id
        email
        piano_user_id
        owner_indc
        registration_date
        linkage_date
        linkage_removed_indc
        naviga_churn_indc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcpianolinkage = /* GraphQL */ `
  query GetAjcpianolinkage($user_id: String!, $access_id: String!) {
    getAjcpianolinkage(user_id: $user_id, access_id: $access_id) {
      user_id
      access_id
      subscription_id
      email
      owner_indc
      start_date
      expire_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcpianolinkages = /* GraphQL */ `
  query ListAjcpianolinkages(
    $user_id: String
    $access_id: ModelStringKeyConditionInput
    $filter: ModelAjcpianolinkageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcpianolinkages(
      user_id: $user_id
      access_id: $access_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        access_id
        subscription_id
        email
        owner_indc
        start_date
        expire_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByPianoLinkageUserId = /* GraphQL */ `
  query SearchByPianoLinkageUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcpianolinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByPianoLinkageUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        access_id
        subscription_id
        email
        owner_indc
        start_date
        expire_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByPianoLinkageEmail = /* GraphQL */ `
  query SearchByPianoLinkageEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcpianolinkageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByPianoLinkageEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        access_id
        subscription_id
        email
        owner_indc
        start_date
        expire_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjcpianouser = /* GraphQL */ `
  query GetAjcpianouser($user_id: String!) {
    getAjcpianouser(user_id: $user_id) {
      user_id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjcpianousers = /* GraphQL */ `
  query ListAjcpianousers(
    $user_id: String
    $filter: ModelAjcpianouserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjcpianousers(
      user_id: $user_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user_id
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchByPianoEmail = /* GraphQL */ `
  query SearchByPianoEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelajcpianouserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByPianoEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user_id
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjclinkageanalytics = /* GraphQL */ `
  query GetAjclinkageanalytics($a_userid: String!) {
    getAjclinkageanalytics(a_userid: $a_userid) {
      a_userid
      a_ncsubscriptionid
      a_accesscountdayslast30dotcom
      a_engagementbehavior
      a_engagementcategory49d
      a_engagementdayssincelastengaged
      a_engagementisunengagerisk
      a_engagementsubcategory49d
      a_engagementvideoswatched60dcount
      a_accesscountdayslast30epaper
      a_hasinterestinpodcasts
      a_preferencescontentsectiontop
      a_preferencesproductplatformlifetime
      a_subscriberchurnrisk
      a_tenureclass
      a_getdate
      a_eventsrsvpdlifetimecount
      a_eventsattendedlifetimecount
      a_surveycompletedlifetimecount
      a_likelihoodtoconvertdo
      a_likelihoodtoconvertso
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjclinkageanalytics = /* GraphQL */ `
  query ListAjclinkageanalytics(
    $a_userid: String
    $filter: ModelAjclinkageanalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjclinkageanalytics(
      a_userid: $a_userid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        a_userid
        a_ncsubscriptionid
        a_accesscountdayslast30dotcom
        a_engagementbehavior
        a_engagementcategory49d
        a_engagementdayssincelastengaged
        a_engagementisunengagerisk
        a_engagementsubcategory49d
        a_engagementvideoswatched60dcount
        a_accesscountdayslast30epaper
        a_hasinterestinpodcasts
        a_preferencescontentsectiontop
        a_preferencesproductplatformlifetime
        a_subscriberchurnrisk
        a_tenureclass
        a_getdate
        a_eventsrsvpdlifetimecount
        a_eventsattendedlifetimecount
        a_surveycompletedlifetimecount
        a_likelihoodtoconvertdo
        a_likelihoodtoconvertso
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAjclinkagesubscriptions = /* GraphQL */ `
  query GetAjclinkagesubscriptions($s_subscription_id: String!) {
    getAjclinkagesubscriptions(s_subscription_id: $s_subscription_id) {
      s_subscription_id
      s_email
      s_first_name
      s_last_name
      s_phone_number
      s_fod
      s_fod_description
      s_billing_method
      s_house_number
      s_auto_pay
      s_last_payment_date
      s_last_payment_amount
      s_expire_date
      s_grace_date
      s_stop_date
      s_paperless_billing
      s_tran_type
      s_create_date
      s_create_time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAjclinkagesubscriptions = /* GraphQL */ `
  query ListAjclinkagesubscriptions(
    $s_subscription_id: String
    $filter: ModelAjclinkagesubscriptionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAjclinkagesubscriptions(
      s_subscription_id: $s_subscription_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        s_subscription_id
        s_email
        s_first_name
        s_last_name
        s_phone_number
        s_fod
        s_fod_description
        s_billing_method
        s_house_number
        s_auto_pay
        s_last_payment_date
        s_last_payment_amount
        s_expire_date
        s_grace_date
        s_stop_date
        s_paperless_billing
        s_tran_type
        s_create_date
        s_create_time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
