import React from 'react';

const TableFooter = ({
  disabledPrev,
  previousHandler,
  currentPage,
  rowPerPage,
  newArray,
  disabledNext,
  handleLoadMore,
}) => {
  return (
    <div className="flex justify-between mb-10">
      <button
        className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
        style={disabledPrev ? { visibility: 'hidden' } : {}}
        onClick={previousHandler}
      >
        Previous
      </button>

      <h2 className="font-prompt-semiBold text-xl ">
        Page {currentPage + 1} of {Math.ceil(newArray.length / rowPerPage)}
      </h2>

      <button
        className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
        style={disabledNext ? { visibility: 'hidden' } : {}}
        onClick={handleLoadMore}
      >
        Next
      </button>
    </div>
  );
};

export default TableFooter;
