import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
  loading: true,
  currentPage: 0,
};

const newOfferSlice = createSlice({
  name: 'newOffer',
  initialState,
  reducers: {
    getOfferData: (state, { payload }) => {
      state.data = payload.offerData;
      state.loading = false;
      state.total = payload.total;
    },
    nextPage: (state) => {
      state.currentPage += 1;
    },
    previousPage: (state) => {
      state.currentPage -= 1;
    },
    setCurrentPage: (state) => {
      state.currentPage = 0;
    },
  },
});

export default newOfferSlice.reducer;

export const { getOfferData, nextPage, previousPage, setCurrentPage } =
  newOfferSlice.actions;
