import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showHtml } from '../../redux/reducers/user';
const SideBar = ({ setIsMenuClicked }) => {
  const dispatch = useDispatch();
  const { isAdmin, adminButton } = useSelector((store) => store.admin);

  return (
    <div className="bg-gray-200 w-1/6 shadow-xl p-4">
      <ul className="flex flex-col gap-4">
        <Link
          to="/"
          className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
          onClick={() => {
            setIsMenuClicked(false);
            dispatch(showHtml());
          }}>
          {adminButton && isAdmin ? 'Edit CSM Home' : 'CSM Home'}
        </Link>
        <Link
          to="/resources"
          className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
          onClick={() => {
            setIsMenuClicked(false);
          }}>
          {adminButton && isAdmin ? 'Edit Resource' : 'Resource'}
        </Link>
        {adminButton && isAdmin && (
          <Link
            to="/templates"
            className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
            onClick={() => {
              setIsMenuClicked(false);
            }}>
            Edit Template
          </Link>
        )}
        {adminButton && isAdmin && (
          <>
            <Link
              to="/delivery"
              className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
              onClick={() => {
                setIsMenuClicked(false);
              }}>
              {adminButton && isAdmin
                ? 'Edit Delivery RateCode'
                : 'Delivery RateCode'}
            </Link>
            <Link
              to="/deliveryzip"
              className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
              onClick={() => {
                setIsMenuClicked(false);
              }}>
              {adminButton && isAdmin ? 'Edit Delivery Zip' : 'Delivery Zip'}
            </Link>
          </>
        )}
        <Link
          to="/offers"
          className="p-3 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-left"
          onClick={() => {
            setIsMenuClicked(false);
          }}>
          {adminButton && isAdmin
            ? 'Edit New Start Offers'
            : 'New Start Offers'}
        </Link>
      </ul>
    </div>
  );
};

export default SideBar;
