import React from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export const CustomSignOutButton = () => {
  const navigate = useNavigate();
  const signOut = async (e) => {
    e.preventDefault();

    try {
      await Auth.signOut();
      navigate('/');
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <button
      className="p-2 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
      onClick={signOut}>
      Sign Out
    </button>
  );
};

export default CustomSignOutButton;
