import { useSelector } from 'react-redux';

const ReadOnlyRow = ({
  tableKeys,
  res,
  editHandler,
  deleteHandler,
  isNewOffer,
  setReadShowModal,
  setModalData,
}) => {
  const { adminButton } = useSelector((store) => store.admin);

  return (
    <tr className="h-10 bg-gray-200">
      {tableKeys.map((t) => (
        <td
          onClick={() => {
            if (isNewOffer) {
              setModalData(res);
              setReadShowModal(true);
            }
          }}
          className="border border-gray-300 font-prompt-regular"
          key={t}
        >
          {res[t] ? res[t] : 'NA'}
        </td>
      ))}

      {adminButton && (
        <td className="border border-gray-300 font-prompt-regular p-2">
          <div className="flex gap-2 p-2 items-center justify-center">
            <button
              className="bg-gray-800  text-white px-2"
              onClick={() => editHandler(res)}
            >
              Edit
            </button>
            <button
              className="bg-gray-800  text-white px-2"
              onClick={() => deleteHandler(res)}
            >
              Delete
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

export default ReadOnlyRow;
