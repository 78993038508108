import { searchBySubscriptionId } from '../../graphql/queries';
import { getAllIssues } from '../../redux/reducers/customerIssues';
import { useDispatch } from 'react-redux';
import { useLazyQuery, gql } from '@apollo/client';

export const GET_DIGITAL_ISSUES = gql`
  ${searchBySubscriptionId}
`;

export const useGetDigitalIssues = (variables) => {
  const dispatch = useDispatch();
  const [getDigitalIssue, { data, error, loading }] = useLazyQuery(
    GET_DIGITAL_ISSUES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'ignore',
      variables,
      onCompleted: (response) => {
        dispatch(getAllIssues(response.searchBySubscriptionId.items));
      },
      onError: (message) => {
        console.error(message);
      },
    }
  );
  return {
    getDigitalIssue,
    data,
    error,
    loading,
  };
};
