import { Link, Outlet } from 'react-router-dom';
import { nextPage, previousPage } from '../../redux/reducers/user';
import { useSelector, useDispatch } from 'react-redux';
import { useGetUserDetails } from '../../queries/queries/useGetUserDetails';

import { useState, useEffect } from 'react';
import Loading from '../HelperComponents/Loading';
import NoData from '../Search/helperComponents/NoData';

const UserList = () => {
  const { data, token, currentPage, total } = useSelector(
    (store) => store.user
  );
  const { searchTerm, filterOption } = useSelector((store) => store.search);
  const [disabledPrev, setdisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  const { loading, error, getUserDetails } = useGetUserDetails();
  const dispatch = useDispatch();
  if (error) {
    console.error(error);
  }

  const tableHeader = [
    ['Subscription Id'],
    'Phone Number',
    'Customer Name',
    'Customer Address',
    'City',
    'State',
    'Email',
    'Communication Status',
    'Substatus',
    'Ipdad Status',
  ];
  const tableKeys = [
    'subscriptionid',
    'phone',
    'customername',
    'address',
    'city',
    'state',
    'email',
    'communicationstatus',
    'substatus',
    'ipadstatus',
  ];

  const handleLoadMore = () => {
    if (token && (currentPage + 1) * 25 >= data.length) {
      const splitWords = searchTerm.split(' ');
      const newFilter = [];
      splitWords.forEach((word) =>
        newFilter.push({ [filterOption]: { wildcard: `${word}*` } })
      );
      getUserDetails({
        variables: {
          filter: { and: newFilter },
          limit: 25,
          nextToken: token,
        },
      });
    }
    dispatch(nextPage());
  };
  const previousHandler = () => {
    dispatch(previousPage());
  };
  useEffect(() => {
    const isDisabled = () => {
      if (currentPage + 1 >= Math.ceil(total / 25)) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      if (currentPage - 1 < 0) {
        setdisabledPrev(true);
      } else {
        setdisabledPrev(false);
      }
    };
    isDisabled();
  }, [currentPage, data, token, total]);
  if (loading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  } else if (total === 0) {
    return <NoData message={'No User Found'} />;
  } else {
    const results = data.slice(currentPage * 25, currentPage * 25 + 25);
    return (
      <div>
        <div className=" mb-7 py-8 px-2 flex">
          <div className="w-full flex">
            <table className="flex-grow" role="table">
              <thead className="h-12 bg-gray-800">
                <tr>
                  {tableHeader.map((header) => (
                    <th
                      key={header}
                      className="border border-black pt-2 font-prompt-semiBold text-white"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {results.map((res) => (
                  <tr key={res.subscriptionid} className="h-10 bg-gray-200">
                    {tableKeys.map((tk) => (
                      <td
                        key={tk}
                        className="border border-gray-300 font-prompt-regular"
                      >
                        <Link
                          to={`${res.subscriptionid}`}
                          state={{ userData: res }}
                          className="block text-center"
                        >
                          {res[tk] ? res[tk] : 'NA'}
                        </Link>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <Outlet />
          </div>
        </div>
        <div className="flex justify-between mb-10">
          <button
            className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
            style={disabledPrev ? { visibility: 'hidden' } : {}}
            onClick={previousHandler}
          >
            Previous
          </button>
          <h2 className="font-prompt-semiBold text-xl ">
            Page {currentPage + 1} of {Math.ceil(total / 25)}
          </h2>
          <button
            className="border p-2 w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
            style={disabledNext ? { visibility: 'hidden' } : {}}
            onClick={() => {
              handleLoadMore();
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
};

export default UserList;
