import React from 'react';

const CustomerCol = ({ data, col }) => {
  return (
    <div className="flex flex-col gap-4 flex-wrap">
      {col.map((d) => (
        <div key={Object.values(d)[0]}>
          <h4 className="inline mr-1 text-base font-semibold ">
            {Object.values(d)[0]} :{' '}
          </h4>
          <h4 className="inline">
            {data[Object.keys(d)[0]] ? data[Object.keys(d)[0]] : 'NA'}
          </h4>
        </div>
      ))}
    </div>
  );
};

export default CustomerCol;
