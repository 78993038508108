import { Parser } from 'html-to-react';
import { useState } from 'react';

const CustomerIssues = ({ data }) => {
  const [activeIssue, setActiveIssue] = useState(0);
  const activeIssueHandler = (id) => {
    setActiveIssue((prevId) => (prevId === id ? 0 : id));
  };
  const issueBaseStyle = {
    height: '0px',
    width: '500px',
    transition: 'height 0.3s ease-in-out',
  };
  const issueActiveStyle = {
    height: '350px',
    width: '500px',
    transition: 'height 0.3s ease-in-out',
    overflowY: 'auto',
  };
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-2xl font-bold my-6 text-gray-500">
        History of Digital Complaints
      </h1>
      {data.map(
        ({ id, csremail, customeremail, issue, issuedescription }, i) => (
          <div key={id} className=" border p-4">
            <h1
              className="text-xl font-bold mb-3   hover:cursor-pointer"
              onClick={() => activeIssueHandler(id)}>
              {i + 1} - {issue}
            </h1>
            <div
              className="h-0 overflow-hidden transition "
              style={activeIssue === id ? issueActiveStyle : issueBaseStyle}>
              <div className="flex flex-col gap-3 overflow-y-auto">
                <div>
                  <h4 className="inline mr-1 text-base font-semibold ">
                    Customer Email :
                  </h4>
                  <h4 className="inline">{customeremail}</h4>
                </div>
                <div>
                  <h4 className="inline mr-1 text-base font-semibold ">
                    Complaint :
                  </h4>
                  <h4 className="inline">{issue}</h4>
                </div>
                <h4 className="inline mr-1 text-base font-semibold ">
                  Complaint Description :
                </h4>
                <div className="border p-2  ">
                  <div className="no-tailwind">
                    {Parser().parse(issuedescription)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CustomerIssues;
