import { gql, useMutation } from '@apollo/client';
import { createAjcoperationsadmineemailtemplates } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const CREATE_ADMIN_TEMPLATE = gql`
  ${createAjcoperationsadmineemailtemplates}
`;

export const useCreateAdminTemplate = (variables) => {
  const [
    createAdminTemplate,
    {
      data: createAdminTemplateData,
      loading: createAdminTemplateLoading,
      error: createAdminTemplateError,
    },
  ] = useMutation(CREATE_ADMIN_TEMPLATE, {
    variables: variables,
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Template created');
    },
    onError: (error) => {
      toast.error('Something went wrong');
      console.log(error);
    },
  });
  return {
    createAdminTemplate,
    createAdminTemplateData,
    createAdminTemplateLoading,
    createAdminTemplateError,
  };
};
