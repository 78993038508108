import { gql, useMutation } from '@apollo/client';
import { createAjcoperationscustomerissues } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const CREATE_DIGITAL_ISSUE = gql`
  ${createAjcoperationscustomerissues}
`;

export const useCreateDigitalIssues = (variables) => {
  const [
    createDigitalIssues,
    { data: createData, error: createError, loading: createLoading },
  ] = useMutation(CREATE_DIGITAL_ISSUE, {
    variables,
    onCompleted: () => {
      toast.success('Content updated');
    },
    onError: (error) => {
      toast.error('Something went wrong');
      console.log(error);
    },
  });
  return {
    createDigitalIssues,
    createData,
    createError,
    createLoading,
  };
};
