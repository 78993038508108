import React from 'react';

const NoData = ({ message }) => {
  return (
    <div className="mt-72">
      <h1 className="text-2xl text-center font-bold	">{message}</h1>
    </div>
  );
};

export default NoData;
