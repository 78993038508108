import { configureStore } from '@reduxjs/toolkit';

import searchReducer from '../redux/reducers/search';
import userReducer from '../redux/reducers/user';
import delivery from './reducers/deliv';
import deliveryZip from './reducers/deliveryZipCode';
import singleUser from './reducers/singleUser';
import admin from './reducers/admin';
import newOffer from './reducers/newOffer';
import adminHtml from './reducers/adminHtml';
import userSchedule from './reducers/userSchedule';
import auth from './reducers/auth';
import customerIssues from './reducers/customerIssues';
export const store = configureStore({
  reducer: {
    search: searchReducer,
    user: userReducer,
    delivery,
    singleUser,
    deliveryZip,
    admin,
    newOffer,
    adminHtml,
    userSchedule,
    auth,
    customerIssues,
  },
});
