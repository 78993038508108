import React from 'react';
import NewOffers from '../../components/NewOffers/default';
const index = () => {
  return (
    <div className="pt-10 max-w-[1600px] mx-auto ">
      <h1 className="text-2xl text-center font-extrabold pb-10">
        New Start Offers
      </h1>
      <NewOffers />
    </div>
  );
};

export default index;
