import { gql, useMutation } from '@apollo/client';
import { updateAjcoperationsadmineemailtemplates } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const UPDATE_ADMIN_TEMPLATE = gql`
  ${updateAjcoperationsadmineemailtemplates}
`;

export const useUpdateAdminTemplate = (variables) => {
  const [
    updateAdminTemplate,
    {
      data: updateTemplateData,
      loading: updateTemplateLoading,
      error: updateTemplateError,
    },
  ] = useMutation(UPDATE_ADMIN_TEMPLATE, {
    variables: variables,
    onCompleted: () => {
      toast.success('Template updated');
    },
    onError: (error) => {
      toast.error('Something went wrong');
      console.log(error);
    },
  });
  return {
    updateAdminTemplate,
    updateTemplateData,
    updateTemplateLoading,
    updateTemplateError,
  };
};
