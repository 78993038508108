import { useMutation, gql } from '@apollo/client';
import { createAjcoperationsadminsubscriptionoverride } from '../../../graphql/mutations';
import { useDispatch } from 'react-redux';
import { toggleAppointment } from '../../../redux/reducers/singleUser';
import { toast } from 'react-toastify';

export const useCreateAppointment = (variables) => {
  const CREATE_APPOINTMENT = gql`
    ${createAjcoperationsadminsubscriptionoverride}
  `;
  const dispatch = useDispatch();
  const [
    createAppointment,
    {
      loading: createAppointmentLoading,
      error: createAppointmentError,
      data: createAppointmentData,
    },
  ] = useMutation(CREATE_APPOINTMENT, {
    variables: variables,
    onCompleted: (data) => {
      dispatch(toggleAppointment());
      toast.success('Created Successfully');
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  return {
    createAppointment,
    createAppointmentLoading,
    createAppointmentError,
    createAppointmentData,
  };
};
