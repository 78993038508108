import { useRef } from 'react';

const Modal = ({ readShowModal, setReadShowModal, data }) => {
  const modalRef = useRef('');
  const modalHandler = (e) => {
    if (e.target === modalRef.current) {
      setReadShowModal(false);
    }
  };
  let results = Object.entries(data);
  const l = results.length;
  results = results.slice(1, l - 3);
  const createTableKeys = [
    //  'id',
    'active',
    //  'site',
    'offercreatedate',
    'offerstartdate',
    'offerexpiredate',
    //  'publication',
    'ratecode',
    'offercode',
    'weeklyprice',
    'totalprice',
    //  'activationfee',
    'subsource',
    'campaigncode',
    'source',
    'deliveryschedule',
    'merchandisecode',
    'defaultoffer',
    'ratetype',
    'offername',
    'offerdescriptiion',
  ];
  const createTableValues = [
    //  'Id',
    'Active',
    //  'Site',
    'Offer Create Date',
    'Offer Start Date',
    'Offer Expire Date',
    //  'Publication',
    'Ratecode',
    'Offer Code',
    'Weekly Price',
    'Total Price',
    //  'Activation Fee',
    'Subsource',
    'Campaign Code',
    'Source',
    'Delivery Schedule',
    'Merchandise Code',
    'Standard Offer',
    'Rate Type',
    'Offer Name',
    'Offer Description',
  ];
  return (
    <>
      {readShowModal && (
        <div
          ref={modalRef}
          onClick={modalHandler}
          className="fixed w-full h-full flex items-center justify-center bg-black bg-opacity-40 left-0 top-0">
          <div className="bg-white w-full max-w-[1600px] p-6">
            <ul className="h-[350px]  w-full flex flex-col items-start justify-center flex-wrap gap-8  ">
              {createTableKeys.map((val, ind) => (
                <li key={ind}>
                  <div className="rounded flex ">
                    <div className="bg-gray-800 text-white p-2 ">
                      {createTableValues[ind]}
                    </div>
                    <div className="bg-gray-200 p-2 max-w-sm  ">
                      {data[val] ? data[val] : 'NA'}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <button
              className="border p-1  bg-gray-800 rounded text-white font-prompt-regular text-sm disabled:bg-dn-nav-color block ml-auto"
              onClick={() => {
                setReadShowModal(false);
              }}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
