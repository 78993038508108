import { useMutation, gql } from '@apollo/client';
import { deleteAjcoperationsadminoffers } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const DELETE_START_OFFER = gql`
  ${deleteAjcoperationsadminoffers}
`;

export const useDeleteStartOffer = (variables) => {
  const [
    deleteStartOffer,
    { error, data: deleteData, loading: deleteLoading },
  ] = useMutation(DELETE_START_OFFER, {
    variables,
    onCompleted: () => {
      toast.success('Deleted Successfully');
    },
    onError: (error) => {
      console.log(error.message);
      toast.error('Something went wrong');
    },
  });

  return { deleteStartOffer, deleteLoading, deleteData };
};
