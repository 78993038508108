import { useMutation, gql } from '@apollo/client';
import { updateAjcoperationsadmindeliveryscheduleratecode } from '../../../graphql/mutations';
export const useUpdateDeliverySchedule = (variables) => {
  const UPDATE_DELIVERY_SCHEDULE = gql`
    ${updateAjcoperationsadmindeliveryscheduleratecode}
  `;
  const [
    UpdateDeliverySchedule,
    {
      error: updateDeliveryError,
      data: updateDeliveryData,
      loading: updateDeliveryLoading,
    },
  ] = useMutation(UPDATE_DELIVERY_SCHEDULE, {
    variables: variables,
    onError: (error) => {
      console.log(error);
    },
  });
  return {
    UpdateDeliverySchedule,
    updateDeliveryLoading,
    updateDeliveryError,
    updateDeliveryData,
  };
};
