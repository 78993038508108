import { getAjcoperationsadminlowermybill } from '../../graphql/queries';
import { useQuery, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getSchedule } from '../../redux/reducers/userSchedule';

export const GET_ADMIN_LOWER_BILLS = gql`
  ${getAjcoperationsadminlowermybill}
`;

export const useAdminLowerBills = (variables) => {
  const dispatch = useDispatch();

  const {
    data: adminLowerBillsData,
    error: adminLowerBillsError,
    loading: adminLowerBillsLoading,
  } = useQuery(GET_ADMIN_LOWER_BILLS, {
    variables,
    onCompleted: (data) => {
      const d = { ...data.getAjcoperationsadminlowermybill };
      dispatch(getSchedule(d));
    },
  });
  return {
    adminLowerBillsData,
    adminLowerBillsError,
    adminLowerBillsLoading,
  };
};
