import Search from '../../components/Search/default';
import UserList from '../../components/UserList/default';
import { useSelector, useDispatch } from 'react-redux';
import AdminHtmlPage from '../../components/AdminHtmlPage/default';
import { useEffect } from 'react';
import { showHtml } from '../../redux/reducers/user';

const Home = () => {
  const { displayUsers, isTriggered } = useSelector((store) => store.user);
  const { adminButton } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminButton) {
      dispatch(showHtml());
    }
  }, [adminButton, dispatch]);

  return (
    <div className="pt-10 max-w-[1600px] mx-auto ">
      {!adminButton && <Search />}
      {displayUsers ? <UserList /> : ''}
      {!isTriggered ? <AdminHtmlPage /> : ''}
    </div>
  );
};

export default Home;
