import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  singleData: [],
  createdApointment: false,
};

const SingleUserSlice = createSlice({
  name: 'singleUser',
  initialState,
  reducers: {
    getUser: (state, { payload }) => {
      state.singleData = payload;
    },
    toggleAppointment: (state) => {
      state.createdApointment = true;
    },
  },
});

export default SingleUserSlice.reducer;

export const { getUser, toggleAppointment } = SingleUserSlice.actions;
