import { useState, useEffect } from 'react';
import { useGetAdminTemplates } from '../../queries/queries/useGetAdminTemplates';
import CreateTemplate from './helperComponents/CreateTemplate';
import Toggle from '../HelperComponents/Toggle';
import Loading from '../HelperComponents/Loading';
import { toast } from 'react-toastify';
import { useUpdateAdminTemplate } from '../../queries/mutation/adminTemplates/useUpdateTemplate';
import { useDeleteAdminTemplate } from '../../queries/mutation/adminTemplates/useDeleteAdminTemplate';
import { Link } from 'react-router-dom';

const AdminTemplates = () => {
  const [isEdit, setEdit] = useState('');
  const { getAdminTemplates, templateLoading, templateData } =
    useGetAdminTemplates();
  const items = templateData?.listAjcoperationsadmineemailtemplates.items;
  const { updateAdminTemplate, updateTemplateData, updateTemplateLoading } =
    useUpdateAdminTemplate();
  const {
    deleteAdminTemplate,
    deleteAdminTemplateData,
    deleteAdminTemplateLoading,
  } = useDeleteAdminTemplate();
  const [template, setTemplate] = useState({
    id: '',
    title: '',
    description: '',
    script: '',
    instructions: '',
  });
  const editHandler = (id, editableObj) => {
    setEdit(id);
    setTemplate(editableObj);
  };
  const deleteHandler = (editableObj) => {
    deleteAdminTemplate({
      variables: {
        input: editableObj,
      },
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    for (let key in template) {
      if (!template[key]) {
        toast.warn('Please fill all the fields');
        return;
      }
    }
    updateAdminTemplate({
      variables: {
        input: template,
      },
    });
    setTemplate({
      id: '',
      title: '',
      description: '',
      script: '',
      instructions: '',
    });
    setEdit(false);
  };
  const onChangeHandler = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === 'id') {
      value = Number(value);
    }
    setTemplate({ ...template, [key]: value });
  };
  useEffect(() => {
    getAdminTemplates();
  }, [deleteAdminTemplateData, getAdminTemplates, updateTemplateData]);
  if (templateLoading || updateTemplateLoading || deleteAdminTemplateLoading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }

  return (
    <div>
      <Toggle>
        <CreateTemplate />
      </Toggle>

      <div className="flex flex-col gap-8">
        {items?.map((item) =>
          isEdit !== item.id ? (
            <div key={item.id} className="shadow-lg p-4 flex flex-col gap-4">
              <table>
                <tr>
                  <th className="border border-black pt-2 font-prompt-semiBold w-32">
                    <h1>ID </h1>
                  </th>
                  <td className="border border-black font-prompt-regular p-4">
                    <p>{item.id}</p>
                  </td>
                </tr>
                <tr>
                  <th className="border border-black pt-2 font-prompt-semiBold w-32">
                    <h1>Title </h1>
                  </th>
                  <td className="border border-black font-prompt-regular  p-4">
                    <p>{item.title}</p>
                  </td>
                </tr>
                <tr>
                  <th className="border border-black pt-2 font-prompt-semiBold w-32">
                    <h1>Description </h1>
                  </th>
                  <td className="border border-black font-prompt-regular  p-4">
                    <pre>{item.description}</pre>
                  </td>
                </tr>
                <tr>
                  <th className="border border-black pt-2 font-prompt-semiBold w-32">
                    <h1>Script </h1>
                  </th>
                  <td className="border border-black font-prompt-regular  p-4">
                    <pre>{item.script}</pre>
                  </td>
                </tr>
                <tr>
                  <th className="border border-black pt-2 font-prompt-semiBold w-32">
                    <h1>Instructions </h1>
                  </th>
                  <td className="border border-black font-prompt-regular  p-4">
                    <p>{item.instructions}</p>
                  </td>
                </tr>
              </table>
              <div className="flex gap-4 justify-end">
                <button
                  type="button"
                  class="bg-gray-800  text-white px-2"
                  onClick={() => {
                    const { __typename, createdAt, updatedAt, ...rest } = item;
                    editHandler(item.id, rest);
                  }}>
                  Edit
                </button>
                <button
                  type="button"
                  class="bg-gray-800  text-white px-2"
                  onClick={() => {
                    deleteHandler({ id: item.id });
                  }}>
                  Delete
                </button>
              </div>
            </div>
          ) : (
            <div className="fixed w-full h-full flex items-center justify-center bg-black bg-opacity-40 left-0 top-0">
              <form
                key={item.id}
                className=" h-[900px] w-full max-w-[1600px] flex flex-col   bg-white  gap-5 p-8"
                onSubmit={submitHandler}>
                <div className="flex">
                  <div className="flex items-center w-full gap-4">
                    <label htmlFor="id">ID</label>
                    <input
                      type="text"
                      className="border-2 w-1/4 p-2"
                      name="id"
                      id="id"
                      value={template.id}
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                  <div className="flex items-center w-full gap-4">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      className="border-2 w-1/4 p-2"
                      name="title"
                      id="title"
                      value={template.title}
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                </div>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  className="border-2 w-1/2"
                  cols="30"
                  value={template.description}
                  onChange={(e) => onChangeHandler(e)}
                  rows="10"></textarea>
                <label htmlFor="script">Script</label>
                <textarea
                  name="script"
                  id="script"
                  className="border-2 w-1/2"
                  value={template.script}
                  onChange={(e) => onChangeHandler(e)}
                  cols="30"
                  rows="10"></textarea>
                <label htmlFor="instructions">Instructions</label>
                <textarea
                  name="instructions"
                  id="instructions"
                  className="border-2 w-1/2"
                  value={template.instructions}
                  onChange={(e) => onChangeHandler(e)}
                  cols="30"
                  rows="10"></textarea>

                <div className="flex">
                  <button
                    type="submit"
                    className="border p-2 w-40 h-12 rounded font-prompt-semiBold text-l disabled:bg-dn-nav-color text-white bg-gray-800">
                    Save
                  </button>
                  <button
                    type="button"
                    className="border p-2 w-40 h-12 rounded font-prompt-semiBold text-l disabled:bg-dn-nav-color text-white bg-gray-800"
                    onClick={() => setEdit('')}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )
        )}
        <Link
          to="/"
          className="w-40 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color flex flex-col justify-center text-center ml-auto mb-10">
          Back
        </Link>
      </div>
    </div>
  );
};

export default AdminTemplates;
