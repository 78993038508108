import { Amplify, Auth } from 'aws-amplify';
import { AUTH_TYPE } from 'aws-appsync';
import awsconfig from '../aws-exports';

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';

Amplify.configure(awsconfig);

const link = ApolloLink.from([
  createAuthLink({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        Auth.currentSession().then((value) => value.getIdToken().getJwtToken()),
    },
  }),
  createHttpLink({ uri: awsconfig.aws_appsync_graphqlEndpoint }),
]);
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
