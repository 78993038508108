import React, { useState, useEffect } from 'react';
import { useGetUserBySubscription } from '../../../queries/queries/useGetUserBySubscription';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useCreateAppointment } from '../../../queries/mutation/appointment/useCreateAppointment';
import { useUpdateAppointment } from '../../../queries/mutation/appointment/useUpdateAppointment';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';

const AppoinmentCard = ({ id, isAppointment }) => {
  const {
    createAppointment,
    createAppointmentLoading,
    createAppointmentError,
    createAppointmentData,
  } = useCreateAppointment();
  const { updateAppointment, updateLoading, updateError, updateData } =
    useUpdateAppointment();
  const { getUserBySubscription, data, loading } = useGetUserBySubscription();

  useEffect(() => {
    if (createAppointmentData) {
      getUserBySubscription({
        variables: {
          subscriptionid: id,
        },
      });
    }
    if (updateData) {
      getUserBySubscription({
        variables: {
          subscriptionid: id,
        },
      });
    }
  }, [getUserBySubscription, id, createAppointmentData, updateData]);
  useEffect(() => {
    getUserBySubscription({
      variables: {
        subscriptionid: id,
      },
    });
  }, []);

  const { singleData } = useSelector((store) => store.singleUser);
  const appointmentdata = data
    ? data.appointmentdata
    : singleData.appointmentdata;
  const override = data ? data.override : singleData.override;
  let appoinmentDate = '';
  let appoinmentTime = '';
  if (createAppointmentLoading) {
  }
  if (createAppointmentError) {
  }
  if (createAppointmentData) {
  }
  if (updateLoading) {
  }
  if (updateError) {
  }
  if (updateData) {
  }
  if (override) {
    appoinmentDate = new Date(override.appointmentdate);
    appoinmentTime = override.appointmenttime;
  } else if (appointmentdata) {
    appoinmentDate = new Date(appointmentdata.appointmentdate);
    appoinmentTime = appointmentdata.appointmenttime;
  }
  const [startDate, setStartDate] = useState('');

  const formHandler = (event) => {
    event.preventDefault();
    if (startDate) {
      if (!isAppointment) {
        createAppointment({
          variables: {
            input: {
              subscriptionid: id,
              appointmentdate: new Date(
                new Date(startDate).getFullYear(),
                new Date(startDate).getMonth(),
                new Date(startDate).getDate()
              ),
              appointmenttime: new Date(startDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
            },
          },
        });
      } else {
        updateAppointment({
          variables: {
            input: {
              subscriptionid: id,
              appointmentdate: new Date(
                new Date(startDate).getUTCFullYear(),
                new Date(startDate).getUTCMonth(),
                new Date(startDate).getUTCDate()
              ),
              appointmenttime: new Date(startDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
            },
          },
        });
      }
      setStartDate('');
    }
  };
  if (loading || updateLoading || createAppointmentLoading) {
    return (
      <div className="w-full h-[230px] flex justify-center appointment_grid_child mt-10">
        <div className="flex flex-col items-center">
          <div className="text-xl">Updating appointment</div>
          <ReactLoading type="bars" color="d31c1e" />
        </div>
      </div>
    );
  }

  return (
    <div className="shadow-lg flex  justify-around p-8 appointment_grid_child mx-auto mt-12 w-full h-52">
      <div className="w-1/4">
        <h2 className="text-xl font-bold mb-4 ">Appointment Data</h2>
        <div>
          <p className="text-lg font-medium">
            {' '}
            Date :{' '}
            <span className="text-base font-normal">
              {singleData?.appointmentdata?.appointmentdate
                ? format(
                    new Date(singleData?.appointmentdata?.appointmentdate),
                    'dd/MM/yyyy'
                  )
                : 'NA'}
            </span>
          </p>
        </div>
        <div>
          <p className="text-lg font-medium  ">
            {' '}
            Time :{' '}
            <span className="text-base font-normal">
              {singleData?.appointmentdata?.appointmenttime
                ? singleData?.appointmentdata?.appointmenttime
                : 'NA'}
            </span>
          </p>
        </div>
      </div>
      <div className="w-1/4">
        <h2 className="text-xl font-bold mb-4 ">Override Data</h2>
        <div>
          <p className="text-lg font-medium">
            {' '}
            Date :{' '}
            <span className="text-base font-normal">
              {singleData?.override?.appointmentdate
                ? format(
                    new Date(singleData?.override?.appointmentdate),
                    'dd/MM/yyyy'
                  )
                : 'NA'}
            </span>
          </p>
        </div>
        <div>
          <p className="text-lg font-medium">
            {' '}
            Time :{' '}
            <span className="text-base font-normal">
              {singleData?.override?.appointmenttime
                ? singleData?.override?.appointmenttime
                : 'NA'}
            </span>
          </p>
        </div>
      </div>

      <form onSubmit={formHandler} className="w-1/4 flex flex-col gap-6 ">
        <label htmlFor="date" className="text-xl font-bold ">
          Change Date and Time
        </label>
        <DatePicker
          className="border"
          id="date"
          selected={startDate}
          onChange={(date) => {
            if (date) {
              setStartDate(date);
            }
          }}
          showTimeSelect
          minDate={new Date()}
          timeFormat="hh:mm:aa"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />

        <button className="w-28 h-12 bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color">
          {isAppointment ? 'update ' : 'create'}
        </button>
      </form>

      <div className="w-1/4">
        <h2 className="text-xl font-bold mb-4 ">Calendly Link</h2>
        <p>{singleData.calendlylink ? singleData.calendlylink : 'NA'}</p>
      </div>
    </div>
  );
};

export default AppoinmentCard;
