import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
  loading: true,
  currentPage: 0,
};

const deliveryZipCodeSlice = createSlice({
  name: 'deliveryZip',
  initialState,
  reducers: {
    getDeliveryZipData: (state, { payload }) => {
      state.data = payload.zipData;
      state.loading = false;
      state.total = payload.total;
    },
    nextPage: (state) => {
      state.currentPage += 1;
    },
    previousPage: (state) => {
      state.currentPage -= 1;
    },
    setCurrentPage: (state) => {
      state.currentPage = 0;
    },
    filterData: (state, { payload }) => {
      const key = payload.key;
      const term = payload.term;

      if (term) {
        const newArray = current(state.data).filter((item) => {
          return item[key].toLowerCase().includes(term.toLowerCase());
        });
        state.data = newArray;
      }
    },
  },
});

export default deliveryZipCodeSlice.reducer;

export const {
  getDeliveryZipData,
  nextPage,
  previousPage,
  filterData,
  setCurrentPage,
} = deliveryZipCodeSlice.actions;
