import { useState } from 'react';

const Toggle = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="border p-2 w-40 h-12 rounded font-prompt-semiBold text-l disabled:bg-dn-nav-color text-white
       bg-gray-800 mb-5"
      >
        {isOpen ? 'Close' : 'Create'}
      </button>
      {isOpen && props.children}
    </>
  );
};

export default Toggle;
