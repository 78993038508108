import { format } from 'date-fns';
import { Auth } from 'aws-amplify';

import { useGetAdminMailLogins } from '../../../queries/queries/useGetAdminMailLogins';

import ReactLoading from 'react-loading';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateDigitalIssues } from '../../../queries/mutation/digitalIssues/useCreateDigitalIssue';
import { useGetDigitalIssues } from '../../../queries/queries/useGetDigitalIssues';
import { useSelector } from 'react-redux';
const DigitalAccess = ({ data }) => {
  const [selectedIssue, setSelectedIssue] = useState('-');
  const [issueDescription, setIssueDescription] = useState('');

  const { EmailData, emailError, errorLoading } = useGetAdminMailLogins({
    subscriptionid: data.subscriptionid,
  });

  const emailData = EmailData?.getAjcoperationsadminemaillogin;
  if (errorLoading || !EmailData) {
    <ReactLoading type="bars" color="d31c1e" />;
  }
  return (
    <div className=" h-full shadow-lg py-4 px-7 mb-10  rounded min-h-[225px]">
      <h1 className="text-2xl font-bold mb-8 text-gray-500">Digital Access</h1>
      {EmailData ? (
        <div>
          <div className="flex  justify-between">
            <div className="flex flex-col gap-4">
              <div>
                <h4 className="inline mr-1  font-semibold ">Registered: </h4>
                <h4 className="inline">{data?.registered}</h4>
              </div>
              <div>
                <h4 className="inline mr-1  font-semibold ">Engaged: </h4>
                <h4 className="inline">{data?.engaged}</h4>
              </div>
              <div>
                <h4 className="inline mr-1  font-semibold ">
                  Registered Users:
                </h4>
                <h4 className="inline">{emailData?.registeredusers}</h4>
              </div>
              <div>
                <h4 className="inline mr-1  font-semibold ">Active Users: </h4>
                <h4 className="inline">{emailData?.engagedactiveusers}</h4>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h4 className="inline mr-1  font-semibold ">Past 30 Day Usage</h4>
              <div>
                <h4 className="inline mr-1  font-semibold ">ePaper: </h4>
                <h4 className="inline">{emailData?.usageepaper}</h4>
              </div>
              <div>
                <h4 className="inline mr-1 font-semibold ">ajc.com: </h4>
                <h4 className="inline">{emailData?.usagemysite}</h4>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <h4 className="inline mr-1  font-semibold ">
                Registered Users/Last Login
              </h4>
              <div>
                <h4 className="inline  ">{emailData?.mg2email1} </h4>
                {emailData?.mg2email1lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email1lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email3} </h4>
                {emailData?.mg2email3lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email3lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email4} </h4>
                {emailData?.mg2email4lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email4lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email5} </h4>
                {emailData?.mg2email5lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email5lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email6} </h4>
                {emailData?.mg2email6lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email6lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email7} </h4>
                {emailData?.mg2email7lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email7lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
              <div>
                <h4 className="inline  ">{emailData?.mg2email8} </h4>
                {emailData?.mg2email8lastusage && (
                  <h4 className="inline">
                    {format(
                      new Date(emailData.mg2email8lastusage),
                      'dd/MM/yyyy'
                    )}
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ReactLoading type="bars" color="d31c1e" />
      )}
    </div>
  );
};

export default DigitalAccess;
