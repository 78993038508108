import { gql, useMutation } from '@apollo/client';
import { updateAjcoperationsadminhtmlpages } from '../../../graphql/mutations';
import { toast } from 'react-toastify';

export const UPDATE_HTML_PAGES = gql`
  ${updateAjcoperationsadminhtmlpages}
`;

export const useUpdateHtmlPages = (variables) => {
  const [
    updateHtmlPages,
    { data: updateData, error: updateError, loading: updateLoading },
  ] = useMutation(UPDATE_HTML_PAGES, {
    variables,
    onCompleted: () => {
      toast.success('Content updated');
    },
    onError: (error) => {
      toast.error('Something went wrong');
      console.log(error);
    },
  });

  return {
    updateHtmlPages,
    updateData,
    updateError,
    updateLoading,
  };
};
