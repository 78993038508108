import { searchAjcoperationsadminsubscriptions } from '../../graphql/queries';
import { useLazyQuery, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getUserList } from '../../redux/reducers/user';
import { toast } from 'react-toastify';

export const useGetUserDetails = (variables) => {
  const dispatch = useDispatch();
  const GET_ALL = gql`
    ${searchAjcoperationsadminsubscriptions}
  `;
  const [getUserDetails, { loading, error, data }] = useLazyQuery(GET_ALL, {
    variables: variables,
    fetchPolicy: 'network-only',
    onError: () => {
      toast.error('Something went wrong');
    },
    onCompleted: (data) => {
      const key = Object.keys(data)[0];

      dispatch(
        getUserList({
          userData: data[key].items,
          token: data[key].nextToken,
          total: data[key].total,
        })
      );
    },
  });

  return { getUserDetails, loading, error, data };
};
