import { useSelector } from 'react-redux';
import { useAdminLowerBills } from '../../../queries/queries/useAdminLowerBills';

const RateNegotiation = ({ id }) => {
  useAdminLowerBills({ subscriptionid: id });
  const { data } = useSelector((store) => store.userSchedule);
  const { currentUser } = useSelector((store) => store.auth);
  const canSeeRank4 =
    currentUser === 'csr2' ||
    currentUser === 'retention' ||
    currentUser === 'admin';
  const canSeeRank5 =
    currentUser === 'telemarketing' || currentUser === 'admin';
  const canSeeAllRanks = currentUser === 'admin';
  const tableHeadder = [
    'Rank',
    'Delivery Schedule',
    'Monthly Amount',
    'Discount',
    'Change to Billed Rate',
    'Change to Last Paid',
    'Rate Code',
  ];
  const row1 = [
    'deliveryscheduleid',
    'monthlyrate1',
    'discountpercent1',
    'changetolastbilledrate1',
    'changetolastpaidrate1',
    'ratecodeidrank1',
  ];

  const row2 = [
    'deliveryscheduleid',
    'monthlyrate2',
    'discountpercent2',
    'changetolastbilledrate2',
    'changetolastpaidrate2',
    'ratecodeidrank2',
  ];
  console.log(data);
  const row3 = [
    'deliveryscheduleid',
    'monthlyrate3',
    'discountpercent3',
    'changetolastbilledrate3',
    'changetolastpaidrate3',
    'ratecodeidrank3',
  ];
  const row4 = [
    'deliveryscheduleid',
    'monthlyrate4',
    'discountpercent4',
    'changetolastbilledrate4',
    'changetolastpaidrate4',
    'ratecodeidrank4',
  ];
  const row5 = [
    'deliveryscheduleid',
    'monthlyrate5',
    'discountpercent5',
    'changetolastbilledrate5',
    'changetolastpaidrate5',
    'ratecodeidrank5',
  ];
  const row6 = [
    'downgrade1deliveryscheduleid',
    'downgradeoption1rate',
    'discountpercentdowngradeoption1',
    'changetolastbilledratedowngrade1',
    'changetolastpaidratedowngrade1',
    'downgradeoption1ratecodeid',
  ];

  const row7 = [
    'downgrade2deliveryscheduleid',
    'downgradeoption2rate',
    'discountpercentdowngradeoption2',
    'changetolastbilledratedowngrade2',
    'changetolastpaidratedowngrade2',
    'downgradeoption2ratecodeid',
  ];
  
  const row8 = [
    'upgradedeliveryscheduleid',
    'upgradeoptionrate',
    'discountpercentupgradeoption',
    'changetolastbilledrateupgrade',
    'changetolastpaidrateupgrade',
    'upgradeoptionratecodeid',
  ];
  const row9 = [
    'fullupgradedeliveryscheduleid',
    'fullupgradeoptionrate',
    'discountpercentfullupgradeoption',
    'changetolastbilledratefullupgrade',
    'changetolastpaidratefullupgrade',
    'fullupgradeoptionratecodeid',
  ];

  const row10 = [
    'digitaldeliveryscheduleid',
    'digitalrate',
    'discountpercentdigital',
    'changetolastbilledratedigital',
    'changetolastpaidratedigital',
    'digitalratecodeid',
  ];

  const row11 = [
    'digitaldeliveryscheduleid',
    'digital2rate',
    'discountpercentdigital2',
    'changetolastbilledratedigital2',
    'changetolastpaidratedigital2',
    'digital2ratecodeid',
  ];
  return (
    <div className=" h-full shadow-lg py-4 px-7  mb-10  rounded min-h-[225px]">
      <h1 className="text-2xl font-bold mb-8 text-gray-500">
        Rate Negotiation
      </h1>
      <table className="flex-grow w-full " role="table">
        <thead className="h-12 bg-gray-800">
          <tr>
            {tableHeadder.map((headder, i) => (
              <th
                key={i}
                className="border border-black pt-2 font-prompt-semiBold text-white">
                {headder}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="h-10 odd:bg-gray-200">
            <td className="border border-gray-300 bg-[#58C727]  font-prompt-regular pl-3">
              1
            </td>
            {row1.map((d, i) => (
              <td
                key={i}
                className="border border-gray-300 font-prompt-regular pl-3">
                {data[d] || 'NA'}
              </td>
            ))}
          </tr>
          <tr className="h-10 odd:bg-gray-200">
            <td className="border border-gray-300 bg-[#D1E3B3] font-prompt-regular pl-3">
              2
            </td>
            {row2.map((d, i) => (
              <td
                key={i}
                className="border border-gray-300 font-prompt-regular pl-3">
                {data[d] || 'NA'}
              </td>
            ))}
          </tr>
          <tr className="h-10 odd:bg-gray-200">
            <td className="border border-gray-300 bg-[#F8EBC2] font-prompt-regular pl-3">
              3
            </td>
            {row3.map((d, i) => (
              <td
                key={i}
                className="border border-gray-300 font-prompt-regular pl-3">
                {data[d] || 'NA'}
              </td>
            ))}
          </tr>
          {canSeeRank4 && (
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 bg-[#FFC289] font-prompt-regular pl-3">
                4
              </td>
              {row4.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
          )}

          {canSeeRank5 && (
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 bg-[#fe8e78] font-prompt-regular pl-3">
                5
              </td>
              {row5.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
          )}
          <>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Downgrade
              </td>
              {row6.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Downgrade
              </td>
              {row7.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Upgrade
              </td>
              {row8.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Full Upgrade
              </td>
              {row9.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Digital
              </td>
              {row10.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
            <tr className="h-10 odd:bg-gray-200">
              <td className="border border-gray-300 font-prompt-regular pl-3">
                Digital 2
              </td>
              {row11.map((d, i) => (
                <td
                  key={i}
                  className="border border-gray-300 font-prompt-regular pl-3">
                  {data[d] || 'NA'}
                </td>
              ))}
            </tr>
          </>

        </tbody>
      </table>
    </div>
  );
};

export default RateNegotiation;
