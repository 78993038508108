import React from 'react';

const DeliverySearch = ({ value, changeHandler, name }) => {
  return (
    <input
      className="text-black outline-none p-1 rounded w-full"
      type="text"
      value={value}
      name={name}
      onChange={changeHandler}
    />
  );
};

export default DeliverySearch;
