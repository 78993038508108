import { useSelector, useDispatch } from 'react-redux';
import { useGetDeliverySchedule } from '../../queries/queries/useGetDeliverySchedule';

import { useState, useEffect } from 'react';
import {
  previousPage,
  nextPage,
  setCurrentPage,
} from '../../redux/reducers/deliv';
import { useUpdateDeliverySchedule } from '../../queries/mutation/delivery/useUpdateDeliverySchedule';
import { useCreateDeliverySchedule } from '../../queries/mutation/delivery/useCreateDeliverySchedule';
import EditableRow from '../HelperComponents/EditableRow';
import ReadOnlyRow from '../HelperComponents/ReadOnlyRow';
import { useDeleteDeliverySchedule } from '../../queries/mutation/delivery/useDeleteDeliverySchedule';
import Loading from '../HelperComponents/Loading';
import { toast } from 'react-toastify';
import DeliverySearch from '../HelperComponents/DeliverySearch';
import BackButton from '../HelperComponents/BackButton';
import TableFooter from '../HelperComponents/TableFooter';
import Toggle from '../HelperComponents/Toggle';
import CreateTable from '../HelperComponents/CreateTable';

const DeliveryRateCode = () => {
  const dispatch = useDispatch();

  const [disabledPrev, setdisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  const [editable, setEditable] = useState();
  const [currentRow, setCurrentRow] = useState('');
  const { adminButton } = useSelector((store) => store.admin);

  const { updateDeliveryLoading, updateDeliveryError } =
    useUpdateDeliverySchedule();
  if (updateDeliveryError) {
  }
  const {
    createDeliverySchedule,
    createdDdeliveryLoading,
    createdDeliveryData,
  } = useCreateDeliverySchedule();

  const {
    deleteDeliverySchedule,
    deleteDeliveryError,
    deleteDeliveryData,
    deleteDeliveryLoading,
  } = useDeleteDeliverySchedule();
  if (deleteDeliveryError) {
  }
  if (deleteDeliveryData) {
  }
  if (createdDeliveryData) {
  }
  if (deleteDeliveryLoading) {
  }
  const { data, loading, currentPage } = useSelector((store) => store.delivery);
  const [query, setQuery] = useState('');
  const newArray = data.filter((item) => {
    return Object.entries(query).every(([key, val]) => {
      return item[key]
        ?.toLowerCase()
        .replace(/\s/g, '')
        .includes(val.toLowerCase()?.replace(/\s/g, ''));
    });
  });
  const searchHandler = (e) => {
    dispatch(setCurrentPage());
    const q = { ...query };
    if (e?.target?.value.replace(' ', '').length > 0) {
      q[e.target.name] = e.target.value;
    } else if (e?.target?.name in q) {
      delete q[e.target.name];
    }

    setQuery(q);
  };
  const { getDeliverySchedule } = useGetDeliverySchedule();

  useEffect(() => {
    getDeliverySchedule();
  }, [getDeliverySchedule]);

  useEffect(() => {
    if (createdDeliveryData || deleteDeliveryData) {
      getDeliverySchedule();
    }
  }, [getDeliverySchedule, createdDeliveryData, deleteDeliveryData]);

  const results = newArray.slice(currentPage * 10, currentPage * 10 + 10);
  const [formData, setFormData] = useState();
  const [editFormData, setEditFormData] = useState({
    monthlyRate: '',
    minRate: '',
    maxRate: '',
    ipad: '',
    newDeliverySchedule: '',
    newdeliveryscheduleipadcustomer: '',
    newRateCode: '',
  });

  const rowNames = [
    'monthlyRate',
    'minRate',
    'maxRate',
    'ipad',
    'newDeliverySchedule',
    'newRateCode',
  ];
  const editHandler = (res) => {
    setEditable(res.unique);
    setCurrentRow(res);
    setFormData({
      monthlyRate: res.newmonthlyrate,
      minRate: res.currentmonthlyratemin,
      maxRate: res.currentmonthlyratemax,
      ipad: res.ipadcustomer,
      newDeliverySchedule: res.newdeliveryschedule,
      newdeliveryscheduleipadcustomer: res.newdeliveryscheduleipadcustomer,
      newRateCode: res.newratecode,
      unique: res.unique,
    });
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormData(newFormData);
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };
  const handleCancelClick = () => {
    setEditable('');
  };
  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    setEditable('');
    try {
      await deleteDeliverySchedule({
        variables: {
          input: {
            newdeliveryscheduleipadcustomer:
              currentRow.newdeliveryscheduleipadcustomer,
            currentmonthlyratemin: currentRow.currentmonthlyratemin,
          },
        },
      });

      await createDeliverySchedule({
        variables: {
          input: {
            newdeliveryscheduleipadcustomer: ` ${formData.newDeliverySchedule}_${formData.ipad}`,
            newdeliveryschedule: formData.newDeliverySchedule,
            ipadcustomer: formData.ipad,
            currentmonthlyratemin: formData.minRate,
            currentmonthlyratemax: formData.maxRate,
            newmonthlyrate: formData.monthlyRate,
            newratecode: formData.newRateCode,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteHandler = (res) => {
    deleteDeliverySchedule({
      variables: {
        input: {
          newdeliveryscheduleipadcustomer: res.newdeliveryscheduleipadcustomer,

          currentmonthlyratemin: res.currentmonthlyratemin,
        },
      },
    });
  };
  const addFormatHandler = (event) => {
    event.preventDefault();
    if (
      editFormData.newDeliverySchedule &&
      editFormData.ipad &&
      editFormData.newDeliverySchedule &&
      editFormData.ipad &&
      editFormData.minRate &&
      editFormData.maxRate &&
      editFormData.monthlyRate &&
      editFormData.newRateCode
    ) {
      createDeliverySchedule({
        variables: {
          input: {
            newdeliveryscheduleipadcustomer: ` ${editFormData.newDeliverySchedule}_${editFormData.ipad}`,
            newdeliveryschedule: editFormData.newDeliverySchedule,
            ipadcustomer: editFormData.ipad,
            currentmonthlyratemin: editFormData.minRate,
            currentmonthlyratemax: editFormData.maxRate,
            newmonthlyrate: editFormData.monthlyRate,
            newratecode: editFormData.newRateCode,
          },
        },
      });
      setEditFormData({
        monthlyRate: '',
        minRate: '',
        maxRate: '',
        ipad: '',
        newDeliverySchedule: '',
        newdeliveryscheduleipadcustomer: '',
        newRateCode: '',
      });
    } else {
      toast.warning('Please enter all the values');
    }
  };
  const tableHeader = [
    'Monthly Rate',
    'Current Monthly Rate Min',
    'Current Monthly Rate Max',
    'Ipad Customer',
    'New Delivery Schedule',
    'New Rate Code',
  ];

  const tableKeys = [
    'newmonthlyrate',
    'currentmonthlyratemin',
    'currentmonthlyratemax',
    'ipadcustomer',
    'newdeliveryschedule',
    'newratecode',
  ];

  const handleLoadMore = () => {
    dispatch(nextPage());
  };

  const previousHandler = () => {
    dispatch(previousPage());
  };
  useEffect(() => {
    const isDisabled = () => {
      if (currentPage + 1 >= Math.ceil(newArray.length / 10)) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      if (currentPage - 1 < 0) {
        setdisabledPrev(true);
      } else {
        setdisabledPrev(false);
      }
    };
    isDisabled();
  }, [currentPage, data, newArray]);
  if (loading) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }
  if (
    createdDdeliveryLoading ||
    updateDeliveryLoading ||
    deleteDeliveryLoading
  ) {
    return <Loading type="bars" color="d31c1e" text="Fetching Data.." />;
  }
  return (
    <div className="">
      {adminButton && (
        <Toggle>
          <CreateTable
            tableKeys={rowNames}
            tableValues={rowNames}
            createFormData={editFormData}
            changeHandler={handleAddFormChange}
            submitHandler={addFormatHandler}
          />
        </Toggle>
      )}
      <table className="flex-grow w-full mb-10 table-fixed" role="table">
        <thead className="h-12 bg-gray-800">
          <tr>
            {tableHeader.map((header, index) => (
              <th
                key={header}
                className="border border-black pt-2 font-prompt-semiBold text-white">
                <div className="flex flex-col items-center gap-2 p-2">
                  {header}
                  <DeliverySearch
                    value={query[tableKeys[index]] || ''}
                    name={tableKeys[index]}
                    changeHandler={searchHandler}
                  />
                </div>
              </th>
            ))}
            {adminButton && (
              <th className="border border-black pt-2 font-prompt-semiBold text-white">
                Actions
              </th>
            )}
          </tr>
        </thead>

        {results.map((res, id) =>
          editable === res.unique ? (
            <tbody key={id}>
              {adminButton && (
                <EditableRow
                  formData={formData}
                  rowNames={rowNames}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                  handleEditFormSubmit={handleEditFormSubmit}
                />
              )}
            </tbody>
          ) : (
            <tbody key={id}>
              <ReadOnlyRow
                tableKeys={tableKeys}
                res={res}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
              />
            </tbody>
          )
        )}
      </table>

      {newArray.length ? (
        <TableFooter
          disabledPrev={disabledPrev}
          rowPerPage={10}
          previousHandler={previousHandler}
          currentPage={currentPage}
          newArray={newArray}
          disabledNext={disabledNext}
          handleLoadMore={handleLoadMore}
        />
      ) : (
        ''
      )}
      <BackButton />
    </div>
  );
};

export default DeliveryRateCode;
