/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAjcoperationsadminsubscriptions = /* GraphQL */ `
  mutation CreateAjcoperationsadminsubscriptions(
    $input: CreateAjcoperationsadminsubscriptionsInput!
    $condition: ModelAjcoperationsadminsubscriptionsConditionInput
  ) {
    createAjcoperationsadminsubscriptions(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      subscriber
      area
      occupantid
      addressid
      phone
      autopay
      deliveryscheduleid
      laststartdate
      subscriptiontype
      monthlyrate
      originalstartdate
      billingmethod
      customername
      address
      city
      state
      zipcode
      districtid
      routeid
      futurestop
      expiredate
      email
      registered
      engaged
      newdeliveryschedule
      primaryratecode
      primarymonthlyrate
      primaryoffer
      primarytoprate
      secondaryratecode
      secondarymonthlyrate
      secondayoffer
      secondarytoprate
      lastresortratecode
      lastresortmonthlyrate
      lastresortoffer
      lastresorttoprate
      deliverytype
      communicationstatus
      substatus
      ipadstatus
      ipadserialnumber
      futuremovetype
      delivscheddate
      newdeliveryscheduleid
      newratecodeid
      newmonthlyrate
      zonedesignation
      subscribersource
      calendlylink
      updateddate
      override {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      conversionstatus {
        subscriptionid
        conversionstatus
        createdAt
        updatedAt
        __typename
      }
      appointmentdata {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      lowermybill {
        subscriptionid
        capid
        circsystem
        siteid
        productid
        starrating
        deliveryscheduleid
        lastpaidrate
        lastbilledrate
        upgradedeliveryscheduleid
        fullupgradedeliveryscheduleid
        downgrade1deliveryscheduleid
        downgrade2deliveryscheduleid
        digitaldeliveryscheduleid
        ratecodeidrank1
        ratecodeidrank2
        ratecodeidrank3
        ratecodeidrank4
        ratecodeidrank5
        downgradeoption1ratecodeid
        downgradeoption2ratecodeid
        upgradeoptionratecodeid
        fullupgradeoptionratecodeid
        digitalratecodeid
        digital2ratecodeid
        monthlyrate1
        monthlyrate2
        monthlyrate3
        monthlyrate4
        monthlyrate5
        downgradeoption1rate
        downgradeoption2rate
        upgradeoptionrate
        fullupgradeoptionrate
        digitalrate
        digital2rate
        changetolastpaidrate1
        changetolastpaidrate2
        changetolastpaidrate3
        changetolastpaidrate4
        changetolastpaidrate5
        changetolastpaidratedowngrade1
        changetolastpaidratedowngrade2
        changetolastpaidrateupgrade
        changetolastpaidratefullupgrade
        changetolastpaidratedigital
        changetolastpaidratedigital2
        changetolastbilledrate1
        changetolastbilledrate2
        changetolastbilledrate3
        changetolastbilledrate4
        changetolastbilledrate5
        changetolastbilledratedowngrade1
        changetolastbilledratedowngrade2
        changetolastbilledrateupgrade
        changetolastbilledratefullupgrade
        changetolastbilledratedigital
        changetolastbilledratedigital2
        discountpercent1
        discountpercent2
        discountpercent3
        discountpercent4
        discountpercent5
        discountpercentdowngradeoption1
        discountpercentdowngradeoption2
        discountpercentupdowngradeoption
        discountpercentfullupdowngradeoption
        discountpercentdigital
        discountpercentdigital2
        valuation
        createdAt
        updatedAt
        __typename
      }
      emaillogin {
        subscriptionid
        registeredusers
        engagedactiveusers
        usageepaper
        usagemysite
        ncemail1
        ncemail2
        mg2email1
        mg2email2
        mg2email3
        mg2email4
        mg2email5
        mg2email6
        mg2email7
        mg2email8
        mg2email1lastusage
        mg2email2lastusage
        mg2email3lastusage
        mg2email4lastusage
        mg2email5lastusage
        mg2email6lastusage
        mg2email7lastusage
        mg2email8lastusage
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminsubscriptions = /* GraphQL */ `
  mutation UpdateAjcoperationsadminsubscriptions(
    $input: UpdateAjcoperationsadminsubscriptionsInput!
    $condition: ModelAjcoperationsadminsubscriptionsConditionInput
  ) {
    updateAjcoperationsadminsubscriptions(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      subscriber
      area
      occupantid
      addressid
      phone
      autopay
      deliveryscheduleid
      laststartdate
      subscriptiontype
      monthlyrate
      originalstartdate
      billingmethod
      customername
      address
      city
      state
      zipcode
      districtid
      routeid
      futurestop
      expiredate
      email
      registered
      engaged
      newdeliveryschedule
      primaryratecode
      primarymonthlyrate
      primaryoffer
      primarytoprate
      secondaryratecode
      secondarymonthlyrate
      secondayoffer
      secondarytoprate
      lastresortratecode
      lastresortmonthlyrate
      lastresortoffer
      lastresorttoprate
      deliverytype
      communicationstatus
      substatus
      ipadstatus
      ipadserialnumber
      futuremovetype
      delivscheddate
      newdeliveryscheduleid
      newratecodeid
      newmonthlyrate
      zonedesignation
      subscribersource
      calendlylink
      updateddate
      override {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      conversionstatus {
        subscriptionid
        conversionstatus
        createdAt
        updatedAt
        __typename
      }
      appointmentdata {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      lowermybill {
        subscriptionid
        capid
        circsystem
        siteid
        productid
        starrating
        deliveryscheduleid
        lastpaidrate
        lastbilledrate
        upgradedeliveryscheduleid
        fullupgradedeliveryscheduleid
        downgrade1deliveryscheduleid
        downgrade2deliveryscheduleid
        digitaldeliveryscheduleid
        ratecodeidrank1
        ratecodeidrank2
        ratecodeidrank3
        ratecodeidrank4
        ratecodeidrank5
        downgradeoption1ratecodeid
        downgradeoption2ratecodeid
        upgradeoptionratecodeid
        fullupgradeoptionratecodeid
        digitalratecodeid
        digital2ratecodeid
        monthlyrate1
        monthlyrate2
        monthlyrate3
        monthlyrate4
        monthlyrate5
        downgradeoption1rate
        downgradeoption2rate
        upgradeoptionrate
        fullupgradeoptionrate
        digitalrate
        digital2rate
        changetolastpaidrate1
        changetolastpaidrate2
        changetolastpaidrate3
        changetolastpaidrate4
        changetolastpaidrate5
        changetolastpaidratedowngrade1
        changetolastpaidratedowngrade2
        changetolastpaidrateupgrade
        changetolastpaidratefullupgrade
        changetolastpaidratedigital
        changetolastpaidratedigital2
        changetolastbilledrate1
        changetolastbilledrate2
        changetolastbilledrate3
        changetolastbilledrate4
        changetolastbilledrate5
        changetolastbilledratedowngrade1
        changetolastbilledratedowngrade2
        changetolastbilledrateupgrade
        changetolastbilledratefullupgrade
        changetolastbilledratedigital
        changetolastbilledratedigital2
        discountpercent1
        discountpercent2
        discountpercent3
        discountpercent4
        discountpercent5
        discountpercentdowngradeoption1
        discountpercentdowngradeoption2
        discountpercentupdowngradeoption
        discountpercentfullupdowngradeoption
        discountpercentdigital
        discountpercentdigital2
        valuation
        createdAt
        updatedAt
        __typename
      }
      emaillogin {
        subscriptionid
        registeredusers
        engagedactiveusers
        usageepaper
        usagemysite
        ncemail1
        ncemail2
        mg2email1
        mg2email2
        mg2email3
        mg2email4
        mg2email5
        mg2email6
        mg2email7
        mg2email8
        mg2email1lastusage
        mg2email2lastusage
        mg2email3lastusage
        mg2email4lastusage
        mg2email5lastusage
        mg2email6lastusage
        mg2email7lastusage
        mg2email8lastusage
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminsubscriptions = /* GraphQL */ `
  mutation DeleteAjcoperationsadminsubscriptions(
    $input: DeleteAjcoperationsadminsubscriptionsInput!
    $condition: ModelAjcoperationsadminsubscriptionsConditionInput
  ) {
    deleteAjcoperationsadminsubscriptions(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      subscriber
      area
      occupantid
      addressid
      phone
      autopay
      deliveryscheduleid
      laststartdate
      subscriptiontype
      monthlyrate
      originalstartdate
      billingmethod
      customername
      address
      city
      state
      zipcode
      districtid
      routeid
      futurestop
      expiredate
      email
      registered
      engaged
      newdeliveryschedule
      primaryratecode
      primarymonthlyrate
      primaryoffer
      primarytoprate
      secondaryratecode
      secondarymonthlyrate
      secondayoffer
      secondarytoprate
      lastresortratecode
      lastresortmonthlyrate
      lastresortoffer
      lastresorttoprate
      deliverytype
      communicationstatus
      substatus
      ipadstatus
      ipadserialnumber
      futuremovetype
      delivscheddate
      newdeliveryscheduleid
      newratecodeid
      newmonthlyrate
      zonedesignation
      subscribersource
      calendlylink
      updateddate
      override {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      conversionstatus {
        subscriptionid
        conversionstatus
        createdAt
        updatedAt
        __typename
      }
      appointmentdata {
        subscriptionid
        appointmentdate
        appointmenttime
        createdAt
        updatedAt
        __typename
      }
      lowermybill {
        subscriptionid
        capid
        circsystem
        siteid
        productid
        starrating
        deliveryscheduleid
        lastpaidrate
        lastbilledrate
        upgradedeliveryscheduleid
        fullupgradedeliveryscheduleid
        downgrade1deliveryscheduleid
        downgrade2deliveryscheduleid
        digitaldeliveryscheduleid
        ratecodeidrank1
        ratecodeidrank2
        ratecodeidrank3
        ratecodeidrank4
        ratecodeidrank5
        downgradeoption1ratecodeid
        downgradeoption2ratecodeid
        upgradeoptionratecodeid
        fullupgradeoptionratecodeid
        digitalratecodeid
        digital2ratecodeid
        monthlyrate1
        monthlyrate2
        monthlyrate3
        monthlyrate4
        monthlyrate5
        downgradeoption1rate
        downgradeoption2rate
        upgradeoptionrate
        fullupgradeoptionrate
        digitalrate
        digital2rate
        changetolastpaidrate1
        changetolastpaidrate2
        changetolastpaidrate3
        changetolastpaidrate4
        changetolastpaidrate5
        changetolastpaidratedowngrade1
        changetolastpaidratedowngrade2
        changetolastpaidrateupgrade
        changetolastpaidratefullupgrade
        changetolastpaidratedigital
        changetolastpaidratedigital2
        changetolastbilledrate1
        changetolastbilledrate2
        changetolastbilledrate3
        changetolastbilledrate4
        changetolastbilledrate5
        changetolastbilledratedowngrade1
        changetolastbilledratedowngrade2
        changetolastbilledrateupgrade
        changetolastbilledratefullupgrade
        changetolastbilledratedigital
        changetolastbilledratedigital2
        discountpercent1
        discountpercent2
        discountpercent3
        discountpercent4
        discountpercent5
        discountpercentdowngradeoption1
        discountpercentdowngradeoption2
        discountpercentupdowngradeoption
        discountpercentfullupdowngradeoption
        discountpercentdigital
        discountpercentdigital2
        valuation
        createdAt
        updatedAt
        __typename
      }
      emaillogin {
        subscriptionid
        registeredusers
        engagedactiveusers
        usageepaper
        usagemysite
        ncemail1
        ncemail2
        mg2email1
        mg2email2
        mg2email3
        mg2email4
        mg2email5
        mg2email6
        mg2email7
        mg2email8
        mg2email1lastusage
        mg2email2lastusage
        mg2email3lastusage
        mg2email4lastusage
        mg2email5lastusage
        mg2email6lastusage
        mg2email7lastusage
        mg2email8lastusage
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminappointmentdata = /* GraphQL */ `
  mutation CreateAjcoperationsadminappointmentdata(
    $input: CreateAjcoperationsadminappointmentdataInput!
    $condition: ModelAjcoperationsadminappointmentdataConditionInput
  ) {
    createAjcoperationsadminappointmentdata(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminappointmentdata = /* GraphQL */ `
  mutation UpdateAjcoperationsadminappointmentdata(
    $input: UpdateAjcoperationsadminappointmentdataInput!
    $condition: ModelAjcoperationsadminappointmentdataConditionInput
  ) {
    updateAjcoperationsadminappointmentdata(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminappointmentdata = /* GraphQL */ `
  mutation DeleteAjcoperationsadminappointmentdata(
    $input: DeleteAjcoperationsadminappointmentdataInput!
    $condition: ModelAjcoperationsadminappointmentdataConditionInput
  ) {
    deleteAjcoperationsadminappointmentdata(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminconversionstatus = /* GraphQL */ `
  mutation CreateAjcoperationsadminconversionstatus(
    $input: CreateAjcoperationsadminconversionstatusInput!
    $condition: ModelAjcoperationsadminconversionstatusConditionInput
  ) {
    createAjcoperationsadminconversionstatus(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      conversionstatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminconversionstatus = /* GraphQL */ `
  mutation UpdateAjcoperationsadminconversionstatus(
    $input: UpdateAjcoperationsadminconversionstatusInput!
    $condition: ModelAjcoperationsadminconversionstatusConditionInput
  ) {
    updateAjcoperationsadminconversionstatus(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      conversionstatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminconversionstatus = /* GraphQL */ `
  mutation DeleteAjcoperationsadminconversionstatus(
    $input: DeleteAjcoperationsadminconversionstatusInput!
    $condition: ModelAjcoperationsadminconversionstatusConditionInput
  ) {
    deleteAjcoperationsadminconversionstatus(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      conversionstatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminsubscriptionoverride = /* GraphQL */ `
  mutation CreateAjcoperationsadminsubscriptionoverride(
    $input: CreateAjcoperationsadminsubscriptionoverrideInput!
    $condition: ModelAjcoperationsadminsubscriptionoverrideConditionInput
  ) {
    createAjcoperationsadminsubscriptionoverride(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminsubscriptionoverride = /* GraphQL */ `
  mutation UpdateAjcoperationsadminsubscriptionoverride(
    $input: UpdateAjcoperationsadminsubscriptionoverrideInput!
    $condition: ModelAjcoperationsadminsubscriptionoverrideConditionInput
  ) {
    updateAjcoperationsadminsubscriptionoverride(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminsubscriptionoverride = /* GraphQL */ `
  mutation DeleteAjcoperationsadminsubscriptionoverride(
    $input: DeleteAjcoperationsadminsubscriptionoverrideInput!
    $condition: ModelAjcoperationsadminsubscriptionoverrideConditionInput
  ) {
    deleteAjcoperationsadminsubscriptionoverride(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      appointmentdate
      appointmenttime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadmindeliveryschedulebyzipcode = /* GraphQL */ `
  mutation CreateAjcoperationsadmindeliveryschedulebyzipcode(
    $input: CreateAjcoperationsadmindeliveryschedulebyzipcodeInput!
    $condition: ModelAjcoperationsadmindeliveryschedulebyzipcodeConditionInput
  ) {
    createAjcoperationsadmindeliveryschedulebyzipcode(
      input: $input
      condition: $condition
    ) {
      zipcode
      area
      deliverytype
      newdeliveryschedule
      calendlylink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadmindeliveryschedulebyzipcode = /* GraphQL */ `
  mutation UpdateAjcoperationsadmindeliveryschedulebyzipcode(
    $input: UpdateAjcoperationsadmindeliveryschedulebyzipcodeInput!
    $condition: ModelAjcoperationsadmindeliveryschedulebyzipcodeConditionInput
  ) {
    updateAjcoperationsadmindeliveryschedulebyzipcode(
      input: $input
      condition: $condition
    ) {
      zipcode
      area
      deliverytype
      newdeliveryschedule
      calendlylink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadmindeliveryschedulebyzipcode = /* GraphQL */ `
  mutation DeleteAjcoperationsadmindeliveryschedulebyzipcode(
    $input: DeleteAjcoperationsadmindeliveryschedulebyzipcodeInput!
    $condition: ModelAjcoperationsadmindeliveryschedulebyzipcodeConditionInput
  ) {
    deleteAjcoperationsadmindeliveryschedulebyzipcode(
      input: $input
      condition: $condition
    ) {
      zipcode
      area
      deliverytype
      newdeliveryschedule
      calendlylink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadmindeliveryscheduleratecode = /* GraphQL */ `
  mutation CreateAjcoperationsadmindeliveryscheduleratecode(
    $input: CreateAjcoperationsadmindeliveryscheduleratecodeInput!
    $condition: ModelAjcoperationsadmindeliveryscheduleratecodeConditionInput
  ) {
    createAjcoperationsadmindeliveryscheduleratecode(
      input: $input
      condition: $condition
    ) {
      newdeliveryscheduleipadcustomer
      newdeliveryschedule
      ipadcustomer
      currentmonthlyratemin
      currentmonthlyratemax
      newmonthlyrate
      newratecode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadmindeliveryscheduleratecode = /* GraphQL */ `
  mutation UpdateAjcoperationsadmindeliveryscheduleratecode(
    $input: UpdateAjcoperationsadmindeliveryscheduleratecodeInput!
    $condition: ModelAjcoperationsadmindeliveryscheduleratecodeConditionInput
  ) {
    updateAjcoperationsadmindeliveryscheduleratecode(
      input: $input
      condition: $condition
    ) {
      newdeliveryscheduleipadcustomer
      newdeliveryschedule
      ipadcustomer
      currentmonthlyratemin
      currentmonthlyratemax
      newmonthlyrate
      newratecode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadmindeliveryscheduleratecode = /* GraphQL */ `
  mutation DeleteAjcoperationsadmindeliveryscheduleratecode(
    $input: DeleteAjcoperationsadmindeliveryscheduleratecodeInput!
    $condition: ModelAjcoperationsadmindeliveryscheduleratecodeConditionInput
  ) {
    deleteAjcoperationsadmindeliveryscheduleratecode(
      input: $input
      condition: $condition
    ) {
      newdeliveryscheduleipadcustomer
      newdeliveryschedule
      ipadcustomer
      currentmonthlyratemin
      currentmonthlyratemax
      newmonthlyrate
      newratecode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminhtmlpages = /* GraphQL */ `
  mutation CreateAjcoperationsadminhtmlpages(
    $input: CreateAjcoperationsadminhtmlpagesInput!
    $condition: ModelAjcoperationsadminhtmlpagesConditionInput
  ) {
    createAjcoperationsadminhtmlpages(input: $input, condition: $condition) {
      pagename
      pagecontent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminhtmlpages = /* GraphQL */ `
  mutation UpdateAjcoperationsadminhtmlpages(
    $input: UpdateAjcoperationsadminhtmlpagesInput!
    $condition: ModelAjcoperationsadminhtmlpagesConditionInput
  ) {
    updateAjcoperationsadminhtmlpages(input: $input, condition: $condition) {
      pagename
      pagecontent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminhtmlpages = /* GraphQL */ `
  mutation DeleteAjcoperationsadminhtmlpages(
    $input: DeleteAjcoperationsadminhtmlpagesInput!
    $condition: ModelAjcoperationsadminhtmlpagesConditionInput
  ) {
    deleteAjcoperationsadminhtmlpages(input: $input, condition: $condition) {
      pagename
      pagecontent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminoffers = /* GraphQL */ `
  mutation CreateAjcoperationsadminoffers(
    $input: CreateAjcoperationsadminoffersInput!
    $condition: ModelAjcoperationsadminoffersConditionInput
  ) {
    createAjcoperationsadminoffers(input: $input, condition: $condition) {
      id
      active
      offercreatedate
      offerstartdate
      offerexpiredate
      site
      publication
      offercode
      weeklyprice
      totalprice
      activationfee
      offername
      offerdescriptiion
      campaigncode
      source
      subsource
      deliveryschedule
      ratetype
      ratecode
      merchandisecode
      defaultoffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminoffers = /* GraphQL */ `
  mutation UpdateAjcoperationsadminoffers(
    $input: UpdateAjcoperationsadminoffersInput!
    $condition: ModelAjcoperationsadminoffersConditionInput
  ) {
    updateAjcoperationsadminoffers(input: $input, condition: $condition) {
      id
      active
      offercreatedate
      offerstartdate
      offerexpiredate
      site
      publication
      offercode
      weeklyprice
      totalprice
      activationfee
      offername
      offerdescriptiion
      campaigncode
      source
      subsource
      deliveryschedule
      ratetype
      ratecode
      merchandisecode
      defaultoffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminoffers = /* GraphQL */ `
  mutation DeleteAjcoperationsadminoffers(
    $input: DeleteAjcoperationsadminoffersInput!
    $condition: ModelAjcoperationsadminoffersConditionInput
  ) {
    deleteAjcoperationsadminoffers(input: $input, condition: $condition) {
      id
      active
      offercreatedate
      offerstartdate
      offerexpiredate
      site
      publication
      offercode
      weeklyprice
      totalprice
      activationfee
      offername
      offerdescriptiion
      campaigncode
      source
      subsource
      deliveryschedule
      ratetype
      ratecode
      merchandisecode
      defaultoffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminlowermybill = /* GraphQL */ `
  mutation CreateAjcoperationsadminlowermybill(
    $input: CreateAjcoperationsadminlowermybillInput!
    $condition: ModelAjcoperationsadminlowermybillConditionInput
  ) {
    createAjcoperationsadminlowermybill(input: $input, condition: $condition) {
      subscriptionid
      capid
      circsystem
      siteid
      productid
      starrating
      deliveryscheduleid
      lastpaidrate
      lastbilledrate
      upgradedeliveryscheduleid
      fullupgradedeliveryscheduleid
      downgrade1deliveryscheduleid
      downgrade2deliveryscheduleid
      digitaldeliveryscheduleid
      ratecodeidrank1
      ratecodeidrank2
      ratecodeidrank3
      ratecodeidrank4
      ratecodeidrank5
      downgradeoption1ratecodeid
      downgradeoption2ratecodeid
      upgradeoptionratecodeid
      fullupgradeoptionratecodeid
      digitalratecodeid
      digital2ratecodeid
      monthlyrate1
      monthlyrate2
      monthlyrate3
      monthlyrate4
      monthlyrate5
      downgradeoption1rate
      downgradeoption2rate
      upgradeoptionrate
      fullupgradeoptionrate
      digitalrate
      digital2rate
      changetolastpaidrate1
      changetolastpaidrate2
      changetolastpaidrate3
      changetolastpaidrate4
      changetolastpaidrate5
      changetolastpaidratedowngrade1
      changetolastpaidratedowngrade2
      changetolastpaidrateupgrade
      changetolastpaidratefullupgrade
      changetolastpaidratedigital
      changetolastpaidratedigital2
      changetolastbilledrate1
      changetolastbilledrate2
      changetolastbilledrate3
      changetolastbilledrate4
      changetolastbilledrate5
      changetolastbilledratedowngrade1
      changetolastbilledratedowngrade2
      changetolastbilledrateupgrade
      changetolastbilledratefullupgrade
      changetolastbilledratedigital
      changetolastbilledratedigital2
      discountpercent1
      discountpercent2
      discountpercent3
      discountpercent4
      discountpercent5
      discountpercentdowngradeoption1
      discountpercentdowngradeoption2
      discountpercentupdowngradeoption
      discountpercentfullupdowngradeoption
      discountpercentdigital
      discountpercentdigital2
      valuation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminlowermybill = /* GraphQL */ `
  mutation UpdateAjcoperationsadminlowermybill(
    $input: UpdateAjcoperationsadminlowermybillInput!
    $condition: ModelAjcoperationsadminlowermybillConditionInput
  ) {
    updateAjcoperationsadminlowermybill(input: $input, condition: $condition) {
      subscriptionid
      capid
      circsystem
      siteid
      productid
      starrating
      deliveryscheduleid
      lastpaidrate
      lastbilledrate
      upgradedeliveryscheduleid
      fullupgradedeliveryscheduleid
      downgrade1deliveryscheduleid
      downgrade2deliveryscheduleid
      digitaldeliveryscheduleid
      ratecodeidrank1
      ratecodeidrank2
      ratecodeidrank3
      ratecodeidrank4
      ratecodeidrank5
      downgradeoption1ratecodeid
      downgradeoption2ratecodeid
      upgradeoptionratecodeid
      fullupgradeoptionratecodeid
      digitalratecodeid
      digital2ratecodeid
      monthlyrate1
      monthlyrate2
      monthlyrate3
      monthlyrate4
      monthlyrate5
      downgradeoption1rate
      downgradeoption2rate
      upgradeoptionrate
      fullupgradeoptionrate
      digitalrate
      digital2rate
      changetolastpaidrate1
      changetolastpaidrate2
      changetolastpaidrate3
      changetolastpaidrate4
      changetolastpaidrate5
      changetolastpaidratedowngrade1
      changetolastpaidratedowngrade2
      changetolastpaidrateupgrade
      changetolastpaidratefullupgrade
      changetolastpaidratedigital
      changetolastpaidratedigital2
      changetolastbilledrate1
      changetolastbilledrate2
      changetolastbilledrate3
      changetolastbilledrate4
      changetolastbilledrate5
      changetolastbilledratedowngrade1
      changetolastbilledratedowngrade2
      changetolastbilledrateupgrade
      changetolastbilledratefullupgrade
      changetolastbilledratedigital
      changetolastbilledratedigital2
      discountpercent1
      discountpercent2
      discountpercent3
      discountpercent4
      discountpercent5
      discountpercentdowngradeoption1
      discountpercentdowngradeoption2
      discountpercentupdowngradeoption
      discountpercentfullupdowngradeoption
      discountpercentdigital
      discountpercentdigital2
      valuation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminlowermybill = /* GraphQL */ `
  mutation DeleteAjcoperationsadminlowermybill(
    $input: DeleteAjcoperationsadminlowermybillInput!
    $condition: ModelAjcoperationsadminlowermybillConditionInput
  ) {
    deleteAjcoperationsadminlowermybill(input: $input, condition: $condition) {
      subscriptionid
      capid
      circsystem
      siteid
      productid
      starrating
      deliveryscheduleid
      lastpaidrate
      lastbilledrate
      upgradedeliveryscheduleid
      fullupgradedeliveryscheduleid
      downgrade1deliveryscheduleid
      downgrade2deliveryscheduleid
      digitaldeliveryscheduleid
      ratecodeidrank1
      ratecodeidrank2
      ratecodeidrank3
      ratecodeidrank4
      ratecodeidrank5
      downgradeoption1ratecodeid
      downgradeoption2ratecodeid
      upgradeoptionratecodeid
      fullupgradeoptionratecodeid
      digitalratecodeid
      digital2ratecodeid
      monthlyrate1
      monthlyrate2
      monthlyrate3
      monthlyrate4
      monthlyrate5
      downgradeoption1rate
      downgradeoption2rate
      upgradeoptionrate
      fullupgradeoptionrate
      digitalrate
      digital2rate
      changetolastpaidrate1
      changetolastpaidrate2
      changetolastpaidrate3
      changetolastpaidrate4
      changetolastpaidrate5
      changetolastpaidratedowngrade1
      changetolastpaidratedowngrade2
      changetolastpaidrateupgrade
      changetolastpaidratefullupgrade
      changetolastpaidratedigital
      changetolastpaidratedigital2
      changetolastbilledrate1
      changetolastbilledrate2
      changetolastbilledrate3
      changetolastbilledrate4
      changetolastbilledrate5
      changetolastbilledratedowngrade1
      changetolastbilledratedowngrade2
      changetolastbilledrateupgrade
      changetolastbilledratefullupgrade
      changetolastbilledratedigital
      changetolastbilledratedigital2
      discountpercent1
      discountpercent2
      discountpercent3
      discountpercent4
      discountpercent5
      discountpercentdowngradeoption1
      discountpercentdowngradeoption2
      discountpercentupdowngradeoption
      discountpercentfullupdowngradeoption
      discountpercentdigital
      discountpercentdigital2
      valuation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationscustomerissues = /* GraphQL */ `
  mutation CreateAjcoperationscustomerissues(
    $input: CreateAjcoperationscustomerissuesInput!
    $condition: ModelAjcoperationscustomerissuesConditionInput
  ) {
    createAjcoperationscustomerissues(input: $input, condition: $condition) {
      id
      subscriptionid
      csremail
      customeremail
      issue
      issuedescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationscustomerissues = /* GraphQL */ `
  mutation UpdateAjcoperationscustomerissues(
    $input: UpdateAjcoperationscustomerissuesInput!
    $condition: ModelAjcoperationscustomerissuesConditionInput
  ) {
    updateAjcoperationscustomerissues(input: $input, condition: $condition) {
      id
      subscriptionid
      csremail
      customeremail
      issue
      issuedescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationscustomerissues = /* GraphQL */ `
  mutation DeleteAjcoperationscustomerissues(
    $input: DeleteAjcoperationscustomerissuesInput!
    $condition: ModelAjcoperationscustomerissuesConditionInput
  ) {
    deleteAjcoperationscustomerissues(input: $input, condition: $condition) {
      id
      subscriptionid
      csremail
      customeremail
      issue
      issuedescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminemaillogin = /* GraphQL */ `
  mutation CreateAjcoperationsadminemaillogin(
    $input: CreateAjcoperationsadminemailloginInput!
    $condition: ModelAjcoperationsadminemailloginConditionInput
  ) {
    createAjcoperationsadminemaillogin(input: $input, condition: $condition) {
      subscriptionid
      registeredusers
      engagedactiveusers
      usageepaper
      usagemysite
      ncemail1
      ncemail2
      mg2email1
      mg2email2
      mg2email3
      mg2email4
      mg2email5
      mg2email6
      mg2email7
      mg2email8
      mg2email1lastusage
      mg2email2lastusage
      mg2email3lastusage
      mg2email4lastusage
      mg2email5lastusage
      mg2email6lastusage
      mg2email7lastusage
      mg2email8lastusage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminemaillogin = /* GraphQL */ `
  mutation UpdateAjcoperationsadminemaillogin(
    $input: UpdateAjcoperationsadminemailloginInput!
    $condition: ModelAjcoperationsadminemailloginConditionInput
  ) {
    updateAjcoperationsadminemaillogin(input: $input, condition: $condition) {
      subscriptionid
      registeredusers
      engagedactiveusers
      usageepaper
      usagemysite
      ncemail1
      ncemail2
      mg2email1
      mg2email2
      mg2email3
      mg2email4
      mg2email5
      mg2email6
      mg2email7
      mg2email8
      mg2email1lastusage
      mg2email2lastusage
      mg2email3lastusage
      mg2email4lastusage
      mg2email5lastusage
      mg2email6lastusage
      mg2email7lastusage
      mg2email8lastusage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminemaillogin = /* GraphQL */ `
  mutation DeleteAjcoperationsadminemaillogin(
    $input: DeleteAjcoperationsadminemailloginInput!
    $condition: ModelAjcoperationsadminemailloginConditionInput
  ) {
    deleteAjcoperationsadminemaillogin(input: $input, condition: $condition) {
      subscriptionid
      registeredusers
      engagedactiveusers
      usageepaper
      usagemysite
      ncemail1
      ncemail2
      mg2email1
      mg2email2
      mg2email3
      mg2email4
      mg2email5
      mg2email6
      mg2email7
      mg2email8
      mg2email1lastusage
      mg2email2lastusage
      mg2email3lastusage
      mg2email4lastusage
      mg2email5lastusage
      mg2email6lastusage
      mg2email7lastusage
      mg2email8lastusage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadminerecommendations = /* GraphQL */ `
  mutation CreateAjcoperationsadminerecommendations(
    $input: CreateAjcoperationsadminerecommendationsInput!
    $condition: ModelAjcoperationsadminerecommendationsConditionInput
  ) {
    createAjcoperationsadminerecommendations(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      recommendationid
      score
      displayrank
      isactive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadminerecommendations = /* GraphQL */ `
  mutation UpdateAjcoperationsadminerecommendations(
    $input: UpdateAjcoperationsadminerecommendationsInput!
    $condition: ModelAjcoperationsadminerecommendationsConditionInput
  ) {
    updateAjcoperationsadminerecommendations(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      recommendationid
      score
      displayrank
      isactive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadminerecommendations = /* GraphQL */ `
  mutation DeleteAjcoperationsadminerecommendations(
    $input: DeleteAjcoperationsadminerecommendationsInput!
    $condition: ModelAjcoperationsadminerecommendationsConditionInput
  ) {
    deleteAjcoperationsadminerecommendations(
      input: $input
      condition: $condition
    ) {
      subscriptionid
      recommendationid
      score
      displayrank
      isactive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcoperationsadmineemailtemplates = /* GraphQL */ `
  mutation CreateAjcoperationsadmineemailtemplates(
    $input: CreateAjcoperationsadmineemailtemplatesInput!
    $condition: ModelAjcoperationsadmineemailtemplatesConditionInput
  ) {
    createAjcoperationsadmineemailtemplates(
      input: $input
      condition: $condition
    ) {
      id
      title
      description
      script
      instructions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcoperationsadmineemailtemplates = /* GraphQL */ `
  mutation UpdateAjcoperationsadmineemailtemplates(
    $input: UpdateAjcoperationsadmineemailtemplatesInput!
    $condition: ModelAjcoperationsadmineemailtemplatesConditionInput
  ) {
    updateAjcoperationsadmineemailtemplates(
      input: $input
      condition: $condition
    ) {
      id
      title
      description
      script
      instructions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcoperationsadmineemailtemplates = /* GraphQL */ `
  mutation DeleteAjcoperationsadmineemailtemplates(
    $input: DeleteAjcoperationsadmineemailtemplatesInput!
    $condition: ModelAjcoperationsadmineemailtemplatesConditionInput
  ) {
    deleteAjcoperationsadmineemailtemplates(
      input: $input
      condition: $condition
    ) {
      id
      title
      description
      script
      instructions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjclinkage = /* GraphQL */ `
  mutation CreateAjclinkage(
    $input: CreateAjclinkageInput!
    $condition: ModelAjclinkageConditionInput
  ) {
    createAjclinkage(input: $input, condition: $condition) {
      user_id
      subscription_id
      email
      piano_user_id
      owner_indc
      registration_date
      linkage_date
      linkage_removed_indc
      naviga_churn_indc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjclinkage = /* GraphQL */ `
  mutation UpdateAjclinkage(
    $input: UpdateAjclinkageInput!
    $condition: ModelAjclinkageConditionInput
  ) {
    updateAjclinkage(input: $input, condition: $condition) {
      user_id
      subscription_id
      email
      piano_user_id
      owner_indc
      registration_date
      linkage_date
      linkage_removed_indc
      naviga_churn_indc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjclinkage = /* GraphQL */ `
  mutation DeleteAjclinkage(
    $input: DeleteAjclinkageInput!
    $condition: ModelAjclinkageConditionInput
  ) {
    deleteAjclinkage(input: $input, condition: $condition) {
      user_id
      subscription_id
      email
      piano_user_id
      owner_indc
      registration_date
      linkage_date
      linkage_removed_indc
      naviga_churn_indc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcpianolinkage = /* GraphQL */ `
  mutation CreateAjcpianolinkage(
    $input: CreateAjcpianolinkageInput!
    $condition: ModelAjcpianolinkageConditionInput
  ) {
    createAjcpianolinkage(input: $input, condition: $condition) {
      user_id
      access_id
      subscription_id
      email
      owner_indc
      start_date
      expire_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcpianolinkage = /* GraphQL */ `
  mutation UpdateAjcpianolinkage(
    $input: UpdateAjcpianolinkageInput!
    $condition: ModelAjcpianolinkageConditionInput
  ) {
    updateAjcpianolinkage(input: $input, condition: $condition) {
      user_id
      access_id
      subscription_id
      email
      owner_indc
      start_date
      expire_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcpianolinkage = /* GraphQL */ `
  mutation DeleteAjcpianolinkage(
    $input: DeleteAjcpianolinkageInput!
    $condition: ModelAjcpianolinkageConditionInput
  ) {
    deleteAjcpianolinkage(input: $input, condition: $condition) {
      user_id
      access_id
      subscription_id
      email
      owner_indc
      start_date
      expire_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjcpianouser = /* GraphQL */ `
  mutation CreateAjcpianouser(
    $input: CreateAjcpianouserInput!
    $condition: ModelAjcpianouserConditionInput
  ) {
    createAjcpianouser(input: $input, condition: $condition) {
      user_id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjcpianouser = /* GraphQL */ `
  mutation UpdateAjcpianouser(
    $input: UpdateAjcpianouserInput!
    $condition: ModelAjcpianouserConditionInput
  ) {
    updateAjcpianouser(input: $input, condition: $condition) {
      user_id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjcpianouser = /* GraphQL */ `
  mutation DeleteAjcpianouser(
    $input: DeleteAjcpianouserInput!
    $condition: ModelAjcpianouserConditionInput
  ) {
    deleteAjcpianouser(input: $input, condition: $condition) {
      user_id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjclinkageanalytics = /* GraphQL */ `
  mutation CreateAjclinkageanalytics(
    $input: CreateAjclinkageanalyticsInput!
    $condition: ModelAjclinkageanalyticsConditionInput
  ) {
    createAjclinkageanalytics(input: $input, condition: $condition) {
      a_userid
      a_ncsubscriptionid
      a_accesscountdayslast30dotcom
      a_engagementbehavior
      a_engagementcategory49d
      a_engagementdayssincelastengaged
      a_engagementisunengagerisk
      a_engagementsubcategory49d
      a_engagementvideoswatched60dcount
      a_accesscountdayslast30epaper
      a_hasinterestinpodcasts
      a_preferencescontentsectiontop
      a_preferencesproductplatformlifetime
      a_subscriberchurnrisk
      a_tenureclass
      a_getdate
      a_eventsrsvpdlifetimecount
      a_eventsattendedlifetimecount
      a_surveycompletedlifetimecount
      a_likelihoodtoconvertdo
      a_likelihoodtoconvertso
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjclinkageanalytics = /* GraphQL */ `
  mutation UpdateAjclinkageanalytics(
    $input: UpdateAjclinkageanalyticsInput!
    $condition: ModelAjclinkageanalyticsConditionInput
  ) {
    updateAjclinkageanalytics(input: $input, condition: $condition) {
      a_userid
      a_ncsubscriptionid
      a_accesscountdayslast30dotcom
      a_engagementbehavior
      a_engagementcategory49d
      a_engagementdayssincelastengaged
      a_engagementisunengagerisk
      a_engagementsubcategory49d
      a_engagementvideoswatched60dcount
      a_accesscountdayslast30epaper
      a_hasinterestinpodcasts
      a_preferencescontentsectiontop
      a_preferencesproductplatformlifetime
      a_subscriberchurnrisk
      a_tenureclass
      a_getdate
      a_eventsrsvpdlifetimecount
      a_eventsattendedlifetimecount
      a_surveycompletedlifetimecount
      a_likelihoodtoconvertdo
      a_likelihoodtoconvertso
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjclinkageanalytics = /* GraphQL */ `
  mutation DeleteAjclinkageanalytics(
    $input: DeleteAjclinkageanalyticsInput!
    $condition: ModelAjclinkageanalyticsConditionInput
  ) {
    deleteAjclinkageanalytics(input: $input, condition: $condition) {
      a_userid
      a_ncsubscriptionid
      a_accesscountdayslast30dotcom
      a_engagementbehavior
      a_engagementcategory49d
      a_engagementdayssincelastengaged
      a_engagementisunengagerisk
      a_engagementsubcategory49d
      a_engagementvideoswatched60dcount
      a_accesscountdayslast30epaper
      a_hasinterestinpodcasts
      a_preferencescontentsectiontop
      a_preferencesproductplatformlifetime
      a_subscriberchurnrisk
      a_tenureclass
      a_getdate
      a_eventsrsvpdlifetimecount
      a_eventsattendedlifetimecount
      a_surveycompletedlifetimecount
      a_likelihoodtoconvertdo
      a_likelihoodtoconvertso
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAjclinkagesubscriptions = /* GraphQL */ `
  mutation CreateAjclinkagesubscriptions(
    $input: CreateAjclinkagesubscriptionsInput!
    $condition: ModelAjclinkagesubscriptionsConditionInput
  ) {
    createAjclinkagesubscriptions(input: $input, condition: $condition) {
      s_subscription_id
      s_email
      s_first_name
      s_last_name
      s_phone_number
      s_fod
      s_fod_description
      s_billing_method
      s_house_number
      s_auto_pay
      s_last_payment_date
      s_last_payment_amount
      s_expire_date
      s_grace_date
      s_stop_date
      s_paperless_billing
      s_tran_type
      s_create_date
      s_create_time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAjclinkagesubscriptions = /* GraphQL */ `
  mutation UpdateAjclinkagesubscriptions(
    $input: UpdateAjclinkagesubscriptionsInput!
    $condition: ModelAjclinkagesubscriptionsConditionInput
  ) {
    updateAjclinkagesubscriptions(input: $input, condition: $condition) {
      s_subscription_id
      s_email
      s_first_name
      s_last_name
      s_phone_number
      s_fod
      s_fod_description
      s_billing_method
      s_house_number
      s_auto_pay
      s_last_payment_date
      s_last_payment_amount
      s_expire_date
      s_grace_date
      s_stop_date
      s_paperless_billing
      s_tran_type
      s_create_date
      s_create_time
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAjclinkagesubscriptions = /* GraphQL */ `
  mutation DeleteAjclinkagesubscriptions(
    $input: DeleteAjclinkagesubscriptionsInput!
    $condition: ModelAjclinkagesubscriptionsConditionInput
  ) {
    deleteAjclinkagesubscriptions(input: $input, condition: $condition) {
      s_subscription_id
      s_email
      s_first_name
      s_last_name
      s_phone_number
      s_fod
      s_fod_description
      s_billing_method
      s_house_number
      s_auto_pay
      s_last_payment_date
      s_last_payment_amount
      s_expire_date
      s_grace_date
      s_stop_date
      s_paperless_billing
      s_tran_type
      s_create_date
      s_create_time
      createdAt
      updatedAt
      __typename
    }
  }
`;
