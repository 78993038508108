import { useState } from 'react';
import Logo from '../../resources/Logo.svg';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAdminButton } from '../../redux/reducers/admin';
import CustomSignOutButton from './helperComponent/CustomSignOutButton';
import menu from '../../resources/menu.svg';
import { showHtml } from '../../redux/reducers/user';
import SideBar from '../SideBar';

const Header = () => {
  const { isAdmin, adminButton } = useSelector((store) => store.admin);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const { currentUser } = useSelector((store) => store.auth);

  const dispatch = useDispatch();

  return (
    <div className="max-w-[1600px] mx-auto pt-4">
      <div className="flex justify-between items-center">
        {currentUser !== 'noGroup' ? (
          <div
            className="w-[180px] hover:cursor-pointer"
            onClick={() => setIsMenuClicked(!isMenuClicked)}>
            <img className="w-10 max-w-[40px]" src={menu} alt="" />
          </div>
        ) : (
          <div></div>
        )}
        <div className=" w-80  ">
          <Link
            to="/"
            onClick={() => {
              dispatch(showHtml());
            }}>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="flex gap-2 items-center w-[180px]">
          {isAdmin && (
            <button
              className="w-[80px] h-[40px] bg-gray-800 rounded text-white font-prompt-semiBold text-l disabled:bg-dn-nav-color"
              onClick={() => {
                dispatch(toggleAdminButton());
              }}>
              {adminButton && isAdmin ? 'Cancel' : 'Admin'}
            </button>
          )}
          <CustomSignOutButton />
        </div>
      </div>
      {isMenuClicked && <SideBar setIsMenuClicked={setIsMenuClicked} />}
    </div>
  );
};

export default Header;
